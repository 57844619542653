import React from "react";
import PropTypes from 'prop-types';

export function MapButtonMobile({isActive, onClick}) {
  const activeColor = "black";
  const defaultColor = "#6F8195";

  return (
    <svg
      onClick={onClick}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 17.4 10.9 23.6 10.6 23.8C10.4 23.9 10.2 24 10 24C9.8 24 9.6 23.9 9.4 23.8C9.1 23.6 0 17.4 0 10ZM6 10C6 7.8 7.8 6 10 6C12.2 6 14 7.8 14 10C14 12.2 12.2 14 10 14C7.8 14 6 12.2 6 10Z"
        fill={isActive ? activeColor : defaultColor}
      />
    </svg>
  );
}

MapButtonMobile.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func
  };
