import React, { useState } from "react";
import connect from "react-redux/es/connect/connect";
import { FileOpener } from "../file-opener/fileOpener";
import { DOCUMENT_TYPES } from "../../../../../const/const";
import { getFileImageMobile } from "../../../../../utils/utils";
import EyeImage from "../../../../../assets/img/svg/eye.svg";
import { setMapFilterState } from "../../../../../actions/control/setMapFilterState";

let fileUrl = null;

export function RefundFilesPage({
  closeRefundFilesPage,
  ticket,
  setMapFilter,
}) {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const onRefundFilesPageHandler = (evt) => {
    evt.preventDefault();
    closeRefundFilesPage();
  };

  const {
    ticketNumber,
    fileOrder,
    filesAct,
    filesCopyInn,
    filesCopyInsurance,
    filesCopyPassport,
    filesMedicalReturn,
    filesPayMentReceipt,
  } = ticket;

  const openFile = (url) => {
    //window.open(url, "_blank");
    setIsFileOpen(true);
    setMapFilter(true);
    fileUrl = url;
  };

  // const onClickFileHandler = (url) => {
  //   setIsFileOpen(true);
  //   setMapFilter(true);
  //   fileUrl = url;
  // };

  const onCloseFileOpener = () => {
    setIsFileOpen(false);
    setMapFilter(false);
  };

  if (isFileOpen) {
    return <FileOpener fileUrl={fileUrl} onClose={() => onCloseFileOpener()} />;
  }

  return (
    <div className="">
      <div className="refund-file-page-wrapper__mobile">
        <button
          onClick={(evt) => onRefundFilesPageHandler(evt)}
          className="pay-form-back-button-mobile"
        >
          <svg
            width="27"
            height="17"
            viewBox="0 0 27 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 8.5L26 8.5"
              stroke="#519025"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 8.50002L8.40592 1"
              stroke="#519025"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 8.5L8.40592 16"
              stroke="#519025"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
        <h2 className="pay-form__title-mobile">{`Файли заявки №${ticketNumber}`}</h2>
      </div>
      <div className="refund-files-page__file-block">
        {fileOrder.length > 0 && (
          <div className="refund-files-page__file-block__item">
            <h2 className="refund-files-page__file-block__title">
              {DOCUMENT_TYPES.fileOrder}
            </h2>
            <div
              onClick={() => openFile(fileOrder)}
              className="refund-files-page__file-block__item-file"
            >
              <img
                src={getFileImageMobile(fileOrder.split(".").pop())}
                className="refund-files-page__file-block__item-file-image"
                alt="file"
              />
              <span className="refund-files-page__file-block__item-file-text">
                Переглянути
              </span>
              <img src={EyeImage} alt="eye" />
            </div>
          </div>
        )}
        {filesAct.length > 0 && (
          <div className="refund-files-page__file-block__item">
            <h2 className="refund-files-page__file-block__title">
              {DOCUMENT_TYPES.filesAct}
            </h2>
            {filesAct.map((item) => {
              return (
                <div
                  onClick={() => openFile(item)}
                  className="refund-files-page__file-block__item-file"
                >
                  <img
                    src={getFileImageMobile(item.split(".").pop())}
                    className="refund-files-page__file-block__item-file-image"
                    alt="file"
                  />
                  <span className="refund-files-page__file-block__item-file-text">
                    Переглянути
                  </span>
                  <img src={EyeImage} alt="eye" />
                </div>
              );
            })}
          </div>
        )}
        <div className="refund-files-page__file-block__item">
          <h2 className="refund-files-page__file-block__title">
            {DOCUMENT_TYPES.filesCopyInn}
          </h2>
          {filesCopyInn.map((item) => {
            return (
              <div
                onClick={() => openFile(item)}
                className="refund-files-page__file-block__item-file"
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  className="refund-files-page__file-block__item-file-image"
                  alt="file"
                />
                <span className="refund-files-page__file-block__item-file-text">
                  Переглянути
                </span>
                <img src={EyeImage} alt="eye" />
              </div>
            );
          })}
        </div>
        <div className="refund-files-page__file-block__item">
          <h2 className="refund-files-page__file-block__title">
            {DOCUMENT_TYPES.filesCopyInsurance}
          </h2>
          {filesCopyInsurance.map((item) => {
            return (
              <div
                onClick={() => openFile(item)}
                className="refund-files-page__file-block__item-file"
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  className="refund-files-page__file-block__item-file-image"
                  alt="file"
                />
                <span className="refund-files-page__file-block__item-file-text">
                  Переглянути
                </span>
                <img src={EyeImage} alt="eye" />
              </div>
            );
          })}
        </div>
        <div className="refund-files-page__file-block__item">
          <h2 className="refund-files-page__file-block__title">
            {DOCUMENT_TYPES.filesCopyPassport}
          </h2>
          {filesCopyPassport.map((item) => {
            return (
              <div
                onClick={() => openFile(item)}
                className="refund-files-page__file-block__item-file"
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  className="refund-files-page__file-block__item-file-image"
                  alt="file"
                />
                <span className="refund-files-page__file-block__item-file-text">
                  Переглянути
                </span>
                <img src={EyeImage} alt="eye" />
              </div>
            );
          })}
        </div>
        <div className="refund-files-page__file-block__item">
          <h2 className="refund-files-page__file-block__title">
            {DOCUMENT_TYPES.filesMedicalReturn}
          </h2>
          {filesMedicalReturn.map((item) => {
            return (
              <div
                onClick={() => openFile(item)}
                className="refund-files-page__file-block__item-file"
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  className="refund-files-page__file-block__item-file-image"
                  alt="file"
                />
                <span className="refund-files-page__file-block__item-file-text">
                  Переглянути
                </span>
                <img src={EyeImage} alt="eye" />
              </div>
            );
          })}
        </div>
        <div className="refund-files-page__file-block__item">
          <h2 className="refund-files-page__file-block__title">
            {DOCUMENT_TYPES.filesPayMentReceipt}
          </h2>
          {filesPayMentReceipt.map((item) => {
            return (
              <div
                onClick={() => openFile(item)}
                className="refund-files-page__file-block__item-file"
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  className="refund-files-page__file-block__item-file-image"
                  alt="file"
                />
                <span className="refund-files-page__file-block__item-file-text">
                  Переглянути
                </span>
                <img src={EyeImage} alt="eye" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
});

export default connect(null, mapDispatchToProps)(RefundFilesPage);
