import React from "react";
import ErrorGif from "../../../../assets/img/error-screen-img.gif";

export function ErrorSubmitScreen({ onClose }) {
  const onCloseHandle = (evt) => {
    evt.preventDefault();
    onClose();
  };

  return (
    <div className="error-screen-wrapper">
      <div className="success-screen">
        <h2 className="error-screen__title">Не вдалося створити заявку</h2>
        <p className="error-screen__text">Щось пішло не так</p>
        <img className="success-screen_img" src={ErrorGif} alt="error" />
        <div className="success-screen__button-wrapper">
          <button
            onClick={(evt) => onCloseHandle(evt)}
            className="error-screen__button"
          >
            Спробувати ще раз
          </button>
        </div>
      </div>
    </div>
  );
}
