import React, { useState } from "react";
import "./personal-info.scss";
import connect from "react-redux/es/connect/connect";
import {
  transformTelNumberReverse,
  transformTelForCompany,
} from "../../../../validator/tel.validator";
import ModalChangeTel from "../../../modals/modalChangeTel";
import { setMapFilterState } from "../../../../../actions/control/setMapFilterState";
import {
  enableNotifications,
  disableNotifications,
} from "../../../../../actions/api-actions";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../../../../service/token/storage.service";
import ApiService from "../../../../../service/api/api.service";
import AddProfileButton from "../../../main/AddProfileButton";
import DeleteMultyProfileButton from "../../../../common/buttons/DeleteMultyProfileButton";
import { Redirect } from "react-router-dom";
import { isIos } from "../../../../../utils/utils";

function PersonalInfo({
  data,
  setMapFilter,
  allowNotifications,
  banNotifications,
}) {
  const [isPhoneChangeFormOpen, setIsPhoneChangeFormOpen] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(
    TokenService.isNotificationsAllowed()
  );
  const [isMultiLoginMenuOpen, setIsMultiLoginMenuOpen] = useState(false);
  const [multyProfiles, setMultyProfiles] = useState(
    ProfileService.getMultyProfiles()
  );

  const { image, username, phone, birth_date, address, polis, card_number } =
    data;
  const brokerData = data.broker;
  const insuranceData = data.insurance_information;

  const closeTelForm = () => {
    setIsPhoneChangeFormOpen(false);
    setMapFilter(false);
  };

  const openTelPhorm = (evt) => {
    evt.preventDefault();
    setIsPhoneChangeFormOpen(true);
    setMapFilter(true);
  };

  const loadMultiprofileLogOut = (profile) => {
    ProfileService.deleteMultiProfile(data.id);
    TokenService.removeToken();
    ApiService.removeHeader();
    TokenService.removeChatData();
    UserDataService.removeUserData();
    TokenService.saveToken(profile.token);
    ApiService.setHeader();

    refreshPage();
  };

  const logOut = (evt) => {
    evt.preventDefault();
    setIsLogOut(true);
    const multyProfiles = ProfileService.getMultyProfiles();
    const nextProfile = multyProfiles.find((item) => item.id !== data.id);
    if (nextProfile) {
      loadMultiprofileLogOut(nextProfile);
      return;
    }
    if (multyProfiles.length > 0) {
      ProfileService.deleteMultiProfile(data.id);
    }
    TokenService.removeToken();
    TokenService.removePreviosToken();
    TokenService.removeChatData();
    ApiService.removeHeader();
    UserDataService.removeUserData();
  };

  const onNotificationClickHandler = (evt) => {
    evt.preventDefault();
    if (isNotificationAllowed) {
      banNotifications();
      TokenService.disableNotification();
    } else {
      allowNotifications();
      TokenService.enableNotification();
    }
    setIsNotificationAllowed(!isNotificationAllowed);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const loadMultiprofile = (profile) => {
    ProfileService.addCurrentProfile({
      id: data.id,
      userName: data.username,
      image: data.image,
    });

    TokenService.removeToken();
    ApiService.removeHeader();
    TokenService.removeChatData();
    UserDataService.removeUserData();
    TokenService.saveToken(profile.token);
    ApiService.setHeader();

    refreshPage();
  };

  const onDeleteMultiProfileHandler = (id) => {
    setMultyProfiles(multyProfiles.filter((item) => item.id !== id));
    ProfileService.deleteMultiProfile(id);
  };

  React.useEffect(() => {
    setMultyProfiles(multyProfiles.filter((item) => item.id !== data.id));
  }, []);

  if (isLogOut) {
    return <Redirect to={`/login`} />;
  }

  return (
    <>
      {!isPhoneChangeFormOpen && (
        <section className="personal-info">
          <h2 className="personal-info__title">Особистий профіль</h2>

          <div className="personal-info__container">
            <h3 className="personal-info__person-title">Персональні дані</h3>
            <div
              onClick={() => setIsMultiLoginMenuOpen(!isMultiLoginMenuOpen)}
              className="personal-info__photo-container"
            >
              <img className="personal-info__photo" src={image} alt="person" />
              <div className="personal-info__name">{username}</div>
              <div className="personal__info__open-menu-arrow">
                {!isMultiLoginMenuOpen && (
                  <svg
                    width="13"
                    height="8"
                    viewBox="0 0 13 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.9 7.2375L0.757143 1.9875C0.585714 1.8125 0.5 1.6375 0.5 1.375C0.5 1.1125 0.585714 0.9375 0.757143 0.7625C1.1 0.4125 1.61429 0.4125 1.95714 0.7625L6.5 5.4L11.0429 0.7625C11.3857 0.4125 11.9 0.4125 12.2429 0.7625C12.5857 1.1125 12.5857 1.6375 12.2429 1.9875L7.1 7.2375C6.75714 7.5875 6.24286 7.5875 5.9 7.2375Z"
                      fill="#AFAFAF"
                    />
                  </svg>
                )}
                {isMultiLoginMenuOpen && (
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.6 0.2625L11.7429 5.5125C11.9143 5.6875 12 5.8625 12 6.125C12 6.3875 11.9143 6.5625 11.7429 6.7375C11.4 7.0875 10.8857 7.0875 10.5429 6.7375L6 2.1L1.45714 6.7375C1.11429 7.0875 0.6 7.0875 0.257143 6.7375C-0.0857143 6.3875 -0.0857143 5.8625 0.257143 5.5125L5.4 0.2625C5.74286 -0.0874996 6.25714 -0.0874996 6.6 0.2625Z"
                      fill="#AFAFAF"
                    />
                  </svg>
                )}
              </div>
            </div>
            {isMultiLoginMenuOpen &&
              multyProfiles.map((item, index) => {
                if (item.id === data.id) {
                  return null;
                }

                return (
                  <div
                    className={`personal-info__container-multiprofile ${
                      index < multyProfiles.length - 1
                        ? "multilogin-menu__separator-line"
                        : ""
                    }`}
                    style={{
                      borderRadius:
                        index === multyProfiles.length - 1 ? "20px" : "0",
                    }}
                    key={item.id}
                  >
                    <img
                      onClick={() => loadMultiprofile(item)}
                      className="personal-info__photo"
                      src={item.image}
                      alt="person"
                    />
                    <div
                      onClick={() => loadMultiprofile(item)}
                      className="personal-info__name personal-info__name--multiprofile"
                    >
                      {item.userName}
                    </div>
                    <DeleteMultyProfileButton
                      onClick={() => onDeleteMultiProfileHandler(item.id)}
                    />
                  </div>
                );
              })}
            {isMultiLoginMenuOpen && (
              <div className="personal-info____add-proffile-button-container">
                <div className="personal-info____add-proffile-button-wrapper">
                  <AddProfileButton isMobile={true} />
                </div>
              </div>
            )}
            {!isMultiLoginMenuOpen && (
              <div className="personal-info__item">
                <span>Номер телефону:</span>
                <div>
                  <span className="personal-info__item-personal-phone-number">
                    <a href={`tel:${phone}`}>{phone}</a>
                  </span>
                  <button
                    onClick={(evt) => openTelPhorm(evt)}
                    className="personal-info__open-tel-form-button"
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.7375 6.6L1.4875 11.7429C1.3125 11.9143 1.1375 12 0.875 12C0.6125 12 0.4375 11.9143 0.2625 11.7429C-0.0875 11.4 -0.0875 10.8857 0.2625 10.5429L4.9 6L0.2625 1.45714C-0.0875 1.11429 -0.0875 0.6 0.2625 0.257143C0.6125 -0.0857143 1.1375 -0.0857143 1.4875 0.257143L6.7375 5.4C7.0875 5.74286 7.0875 6.25714 6.7375 6.6Z"
                        fill="#AFAFAF"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            {!isMultiLoginMenuOpen && (
              <div className="personal-info__item">
                <span>Дата народження:</span>
                <span>{birth_date}</span>
              </div>
            )}
            {!isMultiLoginMenuOpen && (
              <div className="personal-info__item">
                <span>Адреса:</span>
                <span>{address}</span>
              </div>
            )}
            {!isMultiLoginMenuOpen && (
              <div className="personal-info__item">
                <span>№ ІПН:</span>
                <span>{polis}</span>
              </div>
            )}
            {!isMultiLoginMenuOpen && (
              <div className="personal-info__item">
                <span>№ страхового поліса:</span>
                <span>{card_number}</span>
              </div>
            )}
          </div>

          {brokerData && (
            <div className="personal-info__container">
              <h3 className="personal-info__person-title">
                Контактна особа брокера
              </h3>
              <div className="personal-info__photo-container">
                <img
                  className="personal-info__photo"
                  src={brokerData.imageUrl}
                  alt="calendar"
                />
                <div className="personal-info__name">{brokerData.name}</div>
              </div>
              <div className="personal-info__item">
                <span>Номер телефону:</span>
                <span>
                  <a href={`tel:${brokerData.phone}`}>
                    {transformTelNumberReverse(brokerData.phone)}
                  </a>
                </span>
              </div>
              <div className="personal-info__item">
                <span className="personal-info__item-mail-field-name">
                  Email:
                </span>
                <a
                  className="personal-info__item-mail"
                  href={`mailto:${brokerData.email}`}
                >
                  {brokerData.email}
                </a>
                <a href={`mailto:${brokerData.email}`}>
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9545 0H2.04545C0.886364 0 0 0.866667 0 2V10C0 11.1333 0.886364 12 2.04545 12H12.9545C14.1136 12 15 11.1333 15 10V2C15 0.866667 14.1136 0 12.9545 0ZM2.04577 1.3333H12.9549C13.2276 1.3333 13.4321 1.46663 13.5685 1.7333L7.50031 5.86663L1.43213 1.7333C1.56849 1.46663 1.77304 1.3333 2.04577 1.3333ZM1.36377 10C1.36377 10.4 1.6365 10.6667 2.04559 10.6667H12.9547C13.3638 10.6667 13.6365 10.4 13.6365 10V3.26666L7.90922 7.2C7.77286 7.26666 7.6365 7.33333 7.50013 7.33333C7.36377 7.33333 7.22741 7.26666 7.09104 7.2L1.36377 3.26666V10Z"
                      fill="#AFAFAF"
                    />
                  </svg>
                </a>
              </div>
            </div>
          )}

          {insuranceData && (
            <div className="personal-info__container">
              <h3 className="personal-info__person-title">Страхова компанія</h3>
              <div className="personal-info__photo-container">
                <img
                  className="personal-info__photo-insuranse"
                  src={insuranceData.imageUrl}
                  alt="insuranse"
                />
                <div className="personal-info__name">
                  {insuranceData.insurance_company}
                </div>
              </div>
              <div className="personal-info__item">
                <span>Зв'язок для допомоги:</span>
                <span>
                  <a href={`tel:${insuranceData.phoneCompany}`}>
                    {transformTelForCompany(insuranceData.phoneCompany)}
                  </a>
                </span>
              </div>
            </div>
          )}
          {!isIos() && ( 
            <div className="personal-info__container">
              <h3 className="personal-info__person-title">Сповіщення</h3>
              <div className="personal-info__item">
                <span>Отримувати сповіщення:</span>
                <button
                  onClick={(evt) => onNotificationClickHandler(evt)}
                  className={`personal-info__notification-button ${
                    isNotificationAllowed
                      ? "personal-info__notification-button--on"
                      : "personal-info__notification-button--off"
                  }`}
                ></button>
              </div>
            </div>
          )}

          <button
            onClick={(evt) => {
              logOut(evt);
            }}
            className="personal-info__logout-button"
          >
            Вийти з особистого кабінету
          </button>
        </section>
      )}
      {isPhoneChangeFormOpen && (
        <ModalChangeTel
          active="active"
          isMobile={true}
          closeTelForm={() => closeTelForm()}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state.user.userData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
  allowNotifications() {
    dispatch(enableNotifications());
  },
  banNotifications() {
    dispatch(disableNotifications());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
