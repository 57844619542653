export const isTel = number => {
    let regexp = /^\+[1-9]{2}\([0-9]{3}\)-[0-9]{3}-[0-9]{2}-[0-9]{2}$/g;

    if(number.match(regexp)) {
        return true;
    } else {
        return false;
    }
};

export const transformTelNumber = number => {
    let regexp = /\D/g,
        firstNumPart = number.substr(3),
        fullNumber = firstNumPart.replace(regexp, '');

    return fullNumber;
};

export const transformTelNumberReverse = number => {
    let telStart = '+38 (',
        tel_part_a = number.substr(0, 3) + ') ',
        tel_part_b = number.substr(3, 3) + '-',
        tel_part_c = number.substr(6, 2) + '-',
        tel_part_d = number.substr(8, 2);


    let tel = telStart + tel_part_a + tel_part_b + tel_part_c + tel_part_d;

    return tel;
}

export const transformTelForCompany = number => {
    let telStartCheck = number.substr(0, 3);

    if(telStartCheck !== '080') return transformTelNumberReverse(number);

    let tel_part_a = number.substr(0, 1),
        tel_part_b = number.substr(1, 3),
        tel_part_c = number.substr(4, 3),
        tel_pard_d = number.substr(7, 3);

    let tel = tel_part_a + ' ' + tel_part_b + ' ' + tel_part_c + ' ' + tel_pard_d;

    return tel;
}

