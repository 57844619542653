import React, {useEffect} from 'react';
import logo from '../../assets/img/logo_color.png';
import { withRouter } from "react-router-dom";
import { TokenService } from '../../service/token/storage.service';
import ApiService from '../../service/api/api.service';

function trouble(props) {

    const logout = () => {
        TokenService.removeToken();
        ApiService.removeHeader();
        props.history.push('/login')
    };

    return (
        <div className="check_in">
            <div className="check_in__logo">
                <img src={logo} alt="logo"/>
            </div>
            <h1 className="caption">Упс...</h1>
            <p className="desc">Виникла помилка</p>
            <p className="desc">Зателефонуйте на номер <a href="tel:+38(044)290-71-71">+38(044)290-71-71</a> для її вирішення</p>
            <div className="link__other">
                <div className="link__other__row">
                    <button type="button" className="button button__success" onClick={logout}>На сторінку авторизації</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(trouble);