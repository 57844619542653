import React, { useEffect, useState } from "react";
import PayForm from "../refundForm/payForm";
import { connect } from "react-redux";
import { TICKETS_PER_PAGE } from "../../../../const/const";
import { PaginationBlock } from "./paginationBlock";
import { getPaginationArray, getArrayPerPage } from "../../../../utils/utils";
import { FileListBlock } from "./fileListBlock";
import { TICKET_STATUS } from "../../../../const/const";

import "./visitList.scss";

const DATA_OPTIONS = [
  "Дата створення заявки",
  "Номер заявки",
  "Перелік доданих до заявки вкладень (доданих файлів)",
  "IBAN",
  "Стутус заявки",
  "",
];

const CANСELLED_STATUS = "cancelled";

let currentTicketNumber;

let paginationArray;

export function RefundList({ inRefundForm = false, notPaidTickets }) {
  const [isFormOpen, setIsFormOpened] = useState(false);
  const [showedTickets, setShowedTickets] = useState([]);
  const [currentPage, setCurrnetPage] = useState(1);
  const [filesInformation, setFilesInformation] = useState({
    fileType: null,
    rowIndex: null,
  });

  if (notPaidTickets !== null) {
    paginationArray = getPaginationArray(notPaidTickets);
  }

  useEffect(() => {
    if (notPaidTickets !== null) {
      setShowedTickets(
        getArrayPerPage(notPaidTickets, TICKETS_PER_PAGE, currentPage)
      );
    }
  }, [notPaidTickets, currentPage]);

  const onPayFormSubmit = () => {
    setIsFormOpened(false);
  };

  const onPayOpenHandler = (evt, id) => {
    evt.preventDefault();
    setIsFormOpened(true);
    currentTicketNumber = id;
  };

  const onChangePageHandler = (pageNumber) => {
    setCurrnetPage(pageNumber);
    setShowedTickets(
      getArrayPerPage(notPaidTickets, TICKETS_PER_PAGE, pageNumber)
    );
  };

  const onFilesClickHandler = (fileType, rowIndex) => {
    setFilesInformation({
      fileType: fileType,
      rowIndex: rowIndex,
    });
  };

  const closeFileInformation = () => {
    setFilesInformation({
      fileType: null,
      rowIndex: null,
    });
  };

  const escFunction = (evt) => {
    if (evt.keyCode === 27) {
      closeFileInformation();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  if (notPaidTickets === null) {
    return null;
  }

  return (
    <div
      className={`visit-table-container ${
        inRefundForm ? "refund-form__visit-table-container" : ""
      }`}
    >
      <h3 className="visit-table-container__title">Історія створених заявок</h3>
      <table className="visit-table">
        <thead>
          <tr>
            {DATA_OPTIONS.map((item) => {
              return (
                <th className="table-head-element" key={item}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {showedTickets.map((item, index) => {
            const { date, ticketNumber, iban, status } = item;

            return (
              <tr key={index}>
                <td>{date}</td>
                <td>{ticketNumber}</td>
                <td className="visit-table__file-list-item">
                  <FileListBlock
                    onFilesClickHandler={(fileType) =>
                      onFilesClickHandler(fileType, index)
                    }
                    filesInformation={filesInformation}
                    ticket={item}
                    rowIndex={index}
                    closeFileInformation={() => closeFileInformation()}
                  />
                </td>
                <td>
                  <div className="refund-list__iban-field">{iban}</div>
                </td>
                <td>{TICKET_STATUS[status]}</td>
                <td>
                  <button
                    onClick={(evt) => onPayOpenHandler(evt, ticketNumber)}
                    className={`refund-button ${
                      status === CANСELLED_STATUS
                        ? "refund-button--disabled"
                        : ""
                    }`}
                    disabled={status === CANСELLED_STATUS}
                  >
                    Виплачено
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {isFormOpen && (
        <PayForm
          onSubmitHandler={() => onPayFormSubmit()}
          onCloseForm={() => setIsFormOpened(false)}
          ticketNumber={currentTicketNumber}
        />
      )}
      {paginationArray.length > 1 && (
        <PaginationBlock
          paginationArray={paginationArray}
          currentPage={currentPage}
          onChangePageHandler={(pageNumber) => onChangePageHandler(pageNumber)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  notPaidTickets: state.refund.notPaidTickets,
});

export default connect(mapStateToProps, null)(RefundList);
