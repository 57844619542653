import React from "react";
import PropTypes from 'prop-types';

export function HomeButtonMobile({isActive, onClick}) {

  const activeColor = 'black';
  const defaultColor = '#6F8195';

  return (
    <svg
      onClick={onClick}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6 0.224745L19.6 7.2168C19.9 7.41657 20 7.71623 20 8.01589V19.0034C20 20.7015 18.7 22 17 22H13V13C13 12.4477 12.5523 12 12 12H8C7.44772 12 7 12.4477 7 13V22H3C1.3 22 0 20.7015 0 19.0034V8.01589C0 7.71623 0.1 7.41657 0.4 7.2168L9.4 0.224745C9.7 -0.0749149 10.2 -0.0749149 10.6 0.224745Z"
        fill={isActive ? activeColor : defaultColor}
      />
    </svg>
  );
}

HomeButtonMobile.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};
