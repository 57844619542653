import React, { useState, useEffect } from "react";
import VisitForm from "./visitForm";
import "./visitList.scss";
import { connect } from "react-redux";
import { PaginationBlock } from "./paginationBlock";
import { getPaginationArray, getArrayPerPage } from "../../../../utils/utils";
import { TICKETS_PER_PAGE } from "../../../../const/const";

const DATA_OPTIONS = [
  "Дата звернення",
  "Номер договору",
  "Программа",
  "Назва закладу",
  "Меддопомога загальна",
  "Меддопомога деталізована",
  "Вартість",
];

let paginationArray = [];

export function VisitList({ visits }) {
  const [isFormOpen, setIsFormOpened] = useState(false);
  const [currentPage, setCurrnetPage] = useState(1);
  const [showedTickets, setShowedTickets] = useState([]);

  if (visits !== null) {
    paginationArray = getPaginationArray(visits);
  }

  const onClickAddFormButtonHandler = () => {
    setIsFormOpened(!isFormOpen);
  };

  const onChangePageHandler = (pageNumber) => {
    setCurrnetPage(pageNumber);
    setShowedTickets(getArrayPerPage(visits, TICKETS_PER_PAGE, pageNumber));
  };

  useEffect(() => {
    if (visits !== null) {
      setShowedTickets(getArrayPerPage(visits, TICKETS_PER_PAGE, currentPage));
    }
  }, [visits, currentPage]);

  return (
    <div className="visit-table-container">
      <div className="visit-table-container__title-wrapper">
        <h3 className="visit-table-container__title">Історія відвідувань</h3>
        <button
          onClick={() => onClickAddFormButtonHandler()}
          className={`visit-button ${
            isFormOpen ? "visit-button--disabled" : ""
          }`}
          disabled={isFormOpen}
        >
          Додати відвідування +
        </button>
      </div>

      {isFormOpen && (
        <VisitForm onSubmitHandle={() => onClickAddFormButtonHandler()} />
      )}
      {visits !== null && (
        <table className="visit-table">
          <thead>
            <tr>
              {DATA_OPTIONS.map((item) => {
                return (
                  <th className="table-head-element" key={item}>
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {showedTickets.map((item, index) => {
              const {
                date,
                contractNumber,
                program,
                medicalFacility,
                medicalCareGeneral,
                medicalCareDetailed,
                price,
              } = item;
              return (
                <tr key={index}>
                  <td>{date}</td>
                  <td>
                    <div className="visit-list__contract-number-field">
                      {contractNumber}
                    </div>
                  </td>
                  <td>{program}</td>
                  <td>{medicalFacility}</td>
                  <td>{medicalCareGeneral}</td>
                  <td>{medicalCareDetailed}</td>
                  <td>{price !== null ? `${price} грн` : ""}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {paginationArray.length > 1 && (
        <PaginationBlock
          paginationArray={paginationArray}
          currentPage={currentPage}
          onChangePageHandler={(pageNumber) => onChangePageHandler(pageNumber)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  visits: state.visits.visitsList,
});

export default connect(mapStateToProps, null)(VisitList);
