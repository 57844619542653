import React, { useState } from "react";
import './notificationPage.scss'
import { connect } from "react-redux";
import { getRefundTickets } from "../../../../actions/api-actions";

export function NotificationInfoPage({
  notification
}) {


  return (
    <div className="notification-info-page-main-block">
      {notification.image ? <img className="notification-page-item-block-big-img" src={notification.image} alt="" /> : <></>}
      <p className="notification-page-item-block-big-description">{notification.message}</p> 
      <div className="notification-page-item-block-button-container">
      {notification.buttons ? <>
      {JSON.parse(notification.buttons).map(el => {
        return (
          <a key={el.link} target="" className="notification-page-watch-more-button" href={el.link}>
            <p>{el.title}</p>
          </a>
        )
      })}
      </> : <></>}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  upadateRefundTickets() {
    dispatch(getRefundTickets());
  },
});

export default connect(null, mapDispatchToProps)(NotificationInfoPage);
