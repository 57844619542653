/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import NullLpuList from '../../../../../src/assets/img/svg/null-lpu-list.svg'
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { sortByDistance } from "../../../../utils/utils";

export function MobileMarkersList({ currentLpu, lpuCategories, userLocation, openAppoitDoctorPage = () => {}, setMedicalInfo = () => {} }) {
  const [visibleItems, setVisibleItems] = useState([]);
  const [sortedLpu, setSortedLpu] = useState([])
  const [startIndex, setStartIndex] = useState(10);
  const [isShowButtonsMenu, setsShowButtonsMenu] = useState('')
  const {latitude, longitude} = userLocation
  const [targetRef, entry] = useIntersectionObserver({ threshold: 1.0, root: null, rootMargin: '10px' })

  const isShowButtons = useCallback((id) => {
      if (isShowButtonsMenu) {
      setsShowButtonsMenu('')
      return
    }
    setsShowButtonsMenu(id)
  }, [isShowButtonsMenu])

  useEffect(() => {
    if (entry?.isIntersecting) {
      if (visibleItems.length === sortedLpu.length) {
        return
      }
        setVisibleItems([...visibleItems, ...sortedLpu.slice(startIndex, startIndex + 10)]);
      setStartIndex(startIndex + 10);
    }

  },[entry?.isIntersecting])

  useEffect(() => {
    if (sortedLpu.length && sortedLpu.length > 10) {
        setVisibleItems(sortedLpu.slice(0, 10));
    } else if (sortedLpu.length) {
        setVisibleItems(sortedLpu);
    }
  }, [sortedLpu]);

  useEffect(() => {
    if (currentLpu.length) {
      if (!latitude && !longitude) {
        setSortedLpu(currentLpu)
      } else {
        let sorted = sortByDistance(currentLpu, latitude, longitude)
        setSortedLpu(sorted)
      }
    }
  },[currentLpu])


  return (
    <>
      <div
      className="mobile-lpu-screen">
         {!currentLpu.length && <img className="mobile-lpu-screen-null" src={NullLpuList} alt="null-icon" />}
        {visibleItems.map((item, index) => {
            return (
                <div className="mobile-lpu-screen-item" key={index} onClick={() => isShowButtons(item.adress)}>
                  <div className="mobile-lpu-screen-item-content">
                    <div className="mobile-lpu-screen-item-status-image" style={{background: item.icon}}></div>
                  <div className="mobile-lpu-screen-item-text-block">
                    <p className="mobile-lpu-screen-item-name">{item.name}</p>
                    <p className="mobile-lpu-screen-item-category">{item.specialization}</p>
                    <p className="mobile-lpu-screen-item-adress">{item.adress}</p>
                  </div>
                  </div>

                  {isShowButtonsMenu === item.adress && <div className="mobile-lpu-screen-item-buttons-container">
                    <button className="mobile-lpu-screen-item-button" onClick={() => openAppoitDoctorPage()}>Створити заявку</button>
                    <a
                      href={`https://www.google.com/maps/dir//${item.position.lat},${item.position.lng}/@,31.29337,9z/`}
                      className="mobile-lpu-screen-item-button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Прокласти маршрут
                    </a>
                  </div>}
                </div>
            )
        })}
        <div ref={targetRef}></div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  lpu: state.lpus.lpu,
  currentLpu: state.lpus.currentLpu,
  lpuCategories: state.lpus.lpuCategories,
  userLocation: state.user.userData.userLocation
});

export default connect(mapStateToProps, null)(MobileMarkersList);
