import React from "react";
import PropTypes from 'prop-types';

export function PersonalButtonMobile({isActive, onClick}) {
  const activeColor = 'black';
  const defaultColor = '#6F8195';

  return (
    <svg
      onClick={onClick}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C6.2 0 4 2.2 4 5C4 7.8 6.2 10 9 10C11.8 10 14 7.8 14 5C14 2.2 11.8 0 9 0ZM18 19V17C18 14.2 15.8 12 13 12H5C2.2 12 0 14.2 0 17V19C0 19.6 0.4 20 1 20H2H3H5H12.5H14.5H16H17C17.6 20 18 19.6 18 19Z"
        fill={isActive ? activeColor : defaultColor}
      />
    </svg>
  );
}

PersonalButtonMobile.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};