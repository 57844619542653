import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TICKETS_PER_PAGE } from "../../../../const/const";
import { PaginationBlock } from "./paginationBlock";
import { getPaginationArray, getArrayPerPage } from "../../../../utils/utils";
import "./visitList.scss";

const DATA_OPTIONS = [
  "№Заявки",
  "Дата відшкодування коштів",
  "Сумма відшкодування",
  "IBAN",
];

let paginationArray;

export function PayHistoryList({ paidTickets }) {
  const [showedTickets, setShowedTickets] = useState([]);
  const [currentPage, setCurrnetPage] = useState(1);

  if (paidTickets !== null) {
    paginationArray = getPaginationArray(paidTickets);
  }

  useEffect(() => {
    if (paidTickets !== null) {
      setShowedTickets(
        getArrayPerPage(paidTickets, TICKETS_PER_PAGE, currentPage)
      );
    }
  }, [paidTickets, currentPage]);

  const onChangePageHandler = (pageNumber) => {
    setCurrnetPage(pageNumber);
    setShowedTickets(
      getArrayPerPage(paidTickets, TICKETS_PER_PAGE, pageNumber)
    );
  };

  if (paidTickets === null) {
    return null;
  }

  return (
    <div className={`visit-table-container`}>
      <h3 className="visit-table-container__title">Історія виплат</h3>
      <table className="visit-table">
        <thead>
          <tr>
            {DATA_OPTIONS.map((item) => {
              return (
                <th className="table-head-element" key={item}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {showedTickets.map((item, index) => {
            const { ticketNumber, iban, refundAmount, refundDate } = item;
            return (
              <tr key={index}>
                <td>{ticketNumber}</td>
                <td>{refundDate}</td>
                <td>{refundAmount}</td>
                <td>{iban}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {paginationArray.length > 1 && (
        <PaginationBlock
          paginationArray={paginationArray}
          currentPage={currentPage}
          onChangePageHandler={(pageNumber) => onChangePageHandler(pageNumber)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  paidTickets: state.refund.paidTickets,
});

export default connect(mapStateToProps, null)(PayHistoryList);
