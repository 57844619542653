import React from "react";

export function PaidTicket({ ticket }) {
  const { ticketNumber, refundDate, iban, refundAmount } = ticket;
  return (
    <div className="paid-ticket">
      <div className="paid-ticket-info">
        <span className="paid-ticket-info__title">№Заявки:</span>
        <span className="paid-ticket-info__data">{ticketNumber}</span>
      </div>
      <div className="paid-ticket-info">
        <span className="paid-ticket-info__title">
          Дата відшкодування коштів:
        </span>
        <span className="paid-ticket-info__data">{refundDate}</span>
      </div>
      <div className="paid-ticket-info">
        <span className="paid-ticket-info__title">IBAN:</span>
        <span className="paid-ticket-info__data">{iban}</span>
      </div>
      <div className="paid-ticket-info">
        <span className="paid-ticket-info__title">Сумма відшкодування:</span>
        <span className="paid-ticket-info__data">{refundAmount}</span>
      </div>
    </div>
  );
}
