import ApiService from "../api/api.service";

const TOKEN_KEY = "access_token";
const PREVIOS_TOKEN = "previos_token";
const TEMP_TOKEN_KEY = "temp_access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const CHAT_DATA = "chat_data";
const FIREBASE_TOKEN = "sentFirebaseMessagingToken1";
const NOTIFICATION_IS_ALLOWED = "notifacationIsAllowed";
const NOTIFICATION_ALLOWED_FROM_APP = "notificationAllowedFromApp";
const GET_FIRST_DATA = "getFirstData";
const FIRST_APP_RUN = "firstAppRun";
const MULTY_PROFILES = "multyProfiles";
const IS_ADDING_NEW_PROFILE = "isAddingNewProfile";
const USER_DATA = "usersData";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
export const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  setPreviosToken() {
    const token = this.getToken();
    if (token) {
      localStorage.setItem(PREVIOS_TOKEN, token);
    }
  },

  getPreviosToken() {
    return localStorage.getItem(PREVIOS_TOKEN);
  },

  removePreviosToken() {
    localStorage.removeItem(PREVIOS_TOKEN);
  },

  getTempToken() {
    return localStorage.getItem(TEMP_TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  saveTempToken(tempAccessToken) {
    localStorage.setItem(TEMP_TOKEN_KEY, tempAccessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  removeTempToken() {
    localStorage.removeItem(TEMP_TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  isLogin() {
    if (
      (localStorage.getItem(TOKEN_KEY) ||
        localStorage.getItem(PREVIOS_TOKEN)) &&
      ApiService.getHeaders() !== undefined
    ) {
      return true;
    }
    return false;
  },

  saveChatData(data) {
    localStorage.setItem(CHAT_DATA, JSON.stringify(data));
  },

  removeChatData() {
    localStorage.removeItem(CHAT_DATA);
  },

  getChatData() {
    return localStorage.getItem(CHAT_DATA);
  },

  setFireBaseToken(token) {
    localStorage.setItem(FIREBASE_TOKEN, token);
  },

  getFireBaseToken() {
    return localStorage.getItem(FIREBASE_TOKEN);
  },

  enableNotification() {
    localStorage.setItem(NOTIFICATION_IS_ALLOWED, true);
  },

  disableNotification() {
    localStorage.setItem(NOTIFICATION_IS_ALLOWED, false);
  },

  isNotificationsAllowed() {
    return localStorage.getItem(NOTIFICATION_IS_ALLOWED) === "true"
      ? true
      : false;
  },

  getFirstData(isDataGeted) {
    return localStorage.setItem(GET_FIRST_DATA, isDataGeted);
  },

  setNotificationAllowedFromApp(value) {
    localStorage.setItem(NOTIFICATION_ALLOWED_FROM_APP, value);
  },

  isNotificationAllowedFromApp() {
    return localStorage.getItem(NOTIFICATION_ALLOWED_FROM_APP);
  },

  setFirstAppRun() {
    localStorage.setItem(FIRST_APP_RUN, true);
  },

  getFirstAppRun() {
    return localStorage.getItem(FIRST_APP_RUN);
  },
};

export const ProfileService = {
  addPreviosTocken() {},

  addCurrentProfile({ id, userName, image }) {
    const profilesString = localStorage.getItem(MULTY_PROFILES);
    const token = TokenService.getToken();

    if (!profilesString) {
      localStorage.setItem(
        MULTY_PROFILES,
        JSON.stringify([{ id, userName, image, token }])
      );
      return;
    }

    let profiles = JSON.parse(profilesString);

    if (profiles.find((item) => item.id === id)) {
      profiles = profiles.map((item) => {
        if (item.id === id) {
          return {
            id,
            userName,
            image,
            token,
          };
        }
        return item;
      });
    } else {
      profiles.push({
        id,
        userName,
        image,
        token,
      });
    }

    localStorage.setItem(MULTY_PROFILES, JSON.stringify(profiles));
  },

  setIsAddingNewProfile() {
    localStorage.setItem(IS_ADDING_NEW_PROFILE, "yes");
  },

  getIsAddingNewProfile() {
    return localStorage.getItem(IS_ADDING_NEW_PROFILE);
  },

  deleteIsAddingNewProfile() {
    localStorage.removeItem(IS_ADDING_NEW_PROFILE);
  },

  getMultyProfiles() {
    const profilesString = localStorage.getItem(MULTY_PROFILES);

    console.log({ profilesString });
    if (!profilesString) {
      return [];
    }

    let profiles = JSON.parse(profilesString);
    return profiles;
  },

  deleteMultiProfile(id) {
    let profiles = this.getMultyProfiles();
    profiles = profiles.filter((item) => item.id !== id);

    localStorage.setItem(MULTY_PROFILES, JSON.stringify(profiles));
  },
};

export const UserDataService = {
  removeUserData() {
    localStorage.removeItem(USER_DATA);
  },
};
