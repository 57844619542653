import React, { useEffect, useState } from "react";
import { FileValidationErrorScreen } from "./fileValidationErrorScreen";

const ERROR_MESSAGE =
  "Невірний формат IBAN. Будь-ласка перевірте і спробуйте знову";

export function IbanInputBlock({
  itemDescription,
  iban,
  isMobile,
  onChange,
  onSubmit,
}) {
  const [localIban, setLocalIban] = useState(iban);
  const [isValiationError, setIsValidationError] = useState(false);

  const onChangeIbanHandler = (evt) => {
    if (evt.target.value.length > 29) {
      setIsValidationError(true);
      return;
    }
    onChange(evt.target.value);
  };

  const onSubmitHandler = (evt) => {
    evt.preventDefault();

    if (localIban.length < 15 || localIban.length > 29) {
      setIsValidationError(true);
      return;
    }
    onSubmit(evt);
  };

  const onCloseErrorScreenHandler = () => {
    setIsValidationError(false);
  };

  useEffect(() => {
    setLocalIban(iban);
  }, [iban]);

  return (
    <>
      {!isMobile && (
        <div className="field-block">
          <div className="field-block__item-iban">
            <p className="field-block__item-description">
              {`${itemDescription}:`}
            </p>
            <div className="field-block__input">
              <input
                className="field-block__input-iban"
                onChange={(evt) => onChangeIbanHandler(evt)}
                type="text"
                value={localIban}
              />
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={`field-block--mobile`}>
          <p className="field-block__item-description--mobile">{`${itemDescription}:`}</p>
          <p className="field-block__item-format-type--mobile">{`IBAN:`}</p>
          <div className="field-block__input">
            <input
              className="field-block__input-iban--mobile"
              onChange={(evt) => onChangeIbanHandler(evt)}
              type="text"
              value={localIban}
            />
          </div>
          <div className="refund-form__atantion--mobile">
            <div className="refund-form__atantion-image">
              <svg
                width="36"
                height="32"
                viewBox="0 0 36 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.33132 31.3632C3.11797 31.8408 3.90462 32 4.69127 32H31.4374C32.6961 32 33.7974 31.5224 34.584 30.5672C35.528 29.7711 36 28.4975 36 27.2239C36 26.4279 35.8427 25.6318 35.3707 24.8358L22.1549 2.38806C21.3683 1.27363 20.4243 0.477612 19.1657 0.159204C17.907 -0.159204 16.6484 -6.62274e-09 15.5471 0.636816C14.9177 0.955224 14.2884 1.59204 13.9738 2.22886L0.600685 24.8358C-0.657958 27.0647 0.128694 30.0896 2.33132 31.3632ZM3.43242 26.4278L16.6482 3.98002C16.8055 3.66161 16.9628 3.50241 17.2775 3.3432C18.0641 3.0248 19.0081 3.184 19.4801 3.98002L32.6959 26.4278C32.8532 26.7462 32.8532 26.9054 32.8532 27.2238C32.8532 27.7014 32.6959 28.0198 32.3812 28.3382C32.0665 28.6566 31.7519 28.8158 31.2799 28.8158H4.69107C4.53374 28.8158 4.21908 28.8158 4.06174 28.6566C3.27509 28.179 2.96043 27.2238 3.43242 26.4278ZM19.6378 17.6721V11.304C19.6378 10.3487 19.0085 9.71191 18.0645 9.71191C17.1205 9.71191 16.4912 10.3487 16.4912 11.304V17.6721C16.4912 18.6273 17.1205 19.2642 18.0645 19.2642C19.0085 19.2642 19.6378 18.6273 19.6378 17.6721ZM19.6375 24.0398C19.6375 24.5174 19.4802 24.8358 19.1655 25.1542C18.8508 25.4726 18.5362 25.6318 17.9069 25.6318C17.4349 25.6318 17.1202 25.4726 16.8055 25.1542C16.4909 24.8358 16.3335 24.5174 16.3335 24.0398C16.3335 23.8533 16.3875 23.7214 16.4323 23.6121C16.4639 23.5349 16.4909 23.4689 16.4909 23.403C16.4909 23.2438 16.6482 23.0846 16.8055 22.9254C16.9629 22.7662 17.1202 22.607 17.2775 22.607C17.5922 22.4478 17.9069 22.4478 18.2215 22.4478C18.3122 22.5395 18.3506 22.5784 18.397 22.5948C18.4311 22.607 18.4695 22.607 18.5362 22.607C18.6148 22.607 18.6542 22.6468 18.6935 22.6866C18.7328 22.7264 18.7722 22.7662 18.8508 22.7662C19.0082 22.7662 19.1655 22.9254 19.1655 22.9254C19.3228 23.0846 19.4802 23.2438 19.4802 23.403C19.6375 23.5622 19.6375 23.8806 19.6375 24.0398Z"
                  fill="#D2AD2A"
                />
              </svg>
            </div>
            <div>
              Увага! Документи на відшкодування подаються для попереднього
              розгляду, це не виключає необхідності надання документів у
              паперовому вигляді
            </div>
          </div>

          <div className={` field-block__input--mobile`}>
            <button
              onClick={(evt) => onSubmitHandler(evt)}
              className="refund-form__next-button"
              disabled={iban === "" || iban === null}
            >
              Подати заявку
            </button>
          </div>
        </div>
      )}
      {isValiationError && (
        <FileValidationErrorScreen
          onCloseHandler={() => onCloseErrorScreenHandler()}
          errorMessage={ERROR_MESSAGE}
        />
      )}
    </>
  );
}
