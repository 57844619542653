import React from "react";
import BackgroundImage from "../../../../assets/img/file-error-image.png";

export function FileValidationErrorScreen({ onCloseHandler, errorMessage }) {
  const onClickCloseButtonHandler = (evt) => {
    evt.preventDefault();
    onCloseHandler();
  };
  return (
    <>
      <div className="error-validation-screen">
        <div className="error-validation-screen__wrapper">
          <button
            onClick={(evt) => onClickCloseButtonHandler(evt)}
            className="error-validation-screen__close-button"
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.78571 8.78571C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78571 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78571L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78571 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78571Z"
                fill="#4E4E4E"
              />
            </svg>
          </button>
          <img
            className="error-validation-screen__image"
            src={BackgroundImage}
            alt="error"
          />
          <p className="error-validation-screen__description">{errorMessage}</p>
        </div>
      </div>
    </>
  );
}
