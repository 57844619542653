import React, { Component } from "react";
import UserInfo from "../main/userInfo";
import BrokerInfo from "../main/brokerInfo";
import InsuranceInfo from "../main/insuranceInfo";
import Chat from "../main/chat";
import { Consult } from "../main/consult";
import ModalCall from "../modals/modalCall";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { getUserData } from "../../../actions/user/getUserData";
import ModalChangeTel from "../modals/modalChangeTel";
import InsuranceCompany from "../main/insuranceCompany";
import VisitList from "../main/lists/visitList";
import MapContainer from "../main/map/mapContainer";
import RefundForm from "../main/refundForm/refundForm";
import RefundList from "../main/lists/refundList";
import PayHistoryList from "../main/lists/payHistoryList";
import InsuranceCard from "../../common/InsuranceCard";
import { AppLoadOffer } from "../modals/appLoadOffer";
import { TokenService } from "../../../service/token/storage.service";
import { enableNotifications } from "../../../actions/api-actions";

let i = 0;

class Main extends Component {
  state = {
    modalClass: "",
    modalChangeTelClass: "",
    userImageUrl: "",
    randomNum: 0,
    chatData: "",
    isRefundFormOpen: false,
    isAppLoadPopapOpen:
      TokenService.getFirstAppRun() === null ||
      TokenService.getFirstAppRun() === undefined,
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.enableNotifications();
    }, 7000);
  }

  chatData = (data) => {
    this.setState({ chatData: data });
  };

  changePhotoUrl = (url) => {
    this.setState({ userImageUrl: url });
  };

  openModalCall = () => {
    this.setState({ modalClass: "active" });
  };

  openModalChangeTel = () => {
    this.setState({ modalChangeTelClass: "active" });
  };

  closeModalCall = () => {
    this.setState({ modalClass: "" });
  };

  closeModalChangeTel = () => {
    this.setState({ modalChangeTelClass: "" });
  };

  createRandom = () => {
    this.setState({ randomNum: Date.now() });
  };

  toogleRefundForm = () => {
    this.setState({ isRefundFormOpen: !this.state.isRefundFormOpen });
  };

  onCloseAppLoadPopapHandler = () => {
    this.setState({ isAppLoadPopapOpen: false });
    TokenService.setFirstAppRun();
  };

  render() {
    const { modalClass, modalChangeTelClass, userImageUrl, isRefundFormOpen } =
      this.state;

    const { showToast, openChat, data } = this.props;

    return (
      <main>
        {this.state.isAppLoadPopapOpen && (
          <AppLoadOffer onClose={() => this.onCloseAppLoadPopapHandler()} />
        )}

        <div className="container-l">
          <div className="breadcrums">
            {/* <a href="">Головна сторінка</a>
            <Link to={`/dashboard`}>Особистий кабінет</Link> */}
          </div>
        </div>
        <div className="container-l row space-between align-center will-wrap will-mt20">
          <div className="main-title_breadcrums">
            {isRefundFormOpen && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.toogleRefundForm()}
              >
                Особистий кабінет
              </div>
            )}
            <h2 className="caption caption__personal-area">
              {!this.state.isRefundFormOpen
                ? "Особистий кабінет"
                : "Заявка на відшкодовування"}
            </h2>
          </div>
          <div className="control-buttons">
            <button
              className="button button__success will-w100 refund-form__open-button"
              onClick={() => this.toogleRefundForm()}
              disabled={isRefundFormOpen ? true : false}
            >
              Повернути гроші
            </button>
            <button
              className="button button__success will-w100 broker-open__button"
              onClick={openChat}
            >
              Зв'язатися з брокером
            </button>
          </div>
        </div>
        <div className="container-l">
          <div className="personal-area">
            <div className="personal-area__wrap">
              <div className="personal-area__wrap__left">
                <div className="personal-area__wrap__left__tabs">
                  <UserInfo
                    showToast={showToast}
                    userImageUrl={userImageUrl}
                    changePhotoUrl={this.changePhotoUrl}
                    openModalChangeTel={this.openModalChangeTel}
                  />
                  <BrokerInfo />
                  <InsuranceCompany />
                </div>
              </div>
              <div className="personal-area__wrap__right">
                {this.state.isRefundFormOpen && <RefundForm />}
                {!this.state.isRefundFormOpen && <InsuranceInfo />}

                {!this.state.isRefundFormOpen && <MapContainer />}

                {/* <Map createRandom={this.createRandom} randomNum={this.state.randomNum}/> */}
                {data.userData.id !== null && (
                  <Chat
                    showToast={showToast}
                    chatClass={this.props.chatClass}
                    closeChat={this.props.closeChat}
                    setActiveBtn={this.props.setActiveBtn}
                    chatData={this.state.chatData}
                    isMobile={false}
                  />
                )}
                {!this.state.isRefundFormOpen && <VisitList />}
                {!this.state.isRefundFormOpen && <RefundList />}
                {!this.state.isRefundFormOpen && <PayHistoryList />}
              </div>
            </div>
          </div>
        </div>
        <Consult openModalCall={this.openModalCall} />
        <ModalCall active={modalClass} closeModalCall={this.closeModalCall} />
        <ModalChangeTel
          active={modalChangeTelClass}
          close={this.closeModalChangeTel}
          showToast={showToast}
        />
      </main>
    );
  }
}

function matchDispatchToProps(dispath) {
  return bindActionCreators(
    {
      getUserData: getUserData,
      enableNotifications: enableNotifications,
    },
    dispath
  );
}

function mapStateToProps(state) {
  return {
    data: state.user,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Main));
