import React from "react";
import SuccessGif from "../../../../assets/img/success-screen.gif";

export function SuccessScreen({ onClose, isDoctor }) {
  const onCloseHandle = (evt) => {
    evt.preventDefault();
    onClose();
  };

  if (isDoctor) {
    return (
    <div className="success-screen-wrapper">
      <div className="success-screen">
        <h2 className="success-screen__title">Ваша заявка прийнята та буде опрацьована в плановому порядку</h2>
        <p className="success-screen__text">
          Протягом 3-х годин очікуйте на зворотній зв'язок від страхової компанії
        </p>
        <img className="success-screen_img" src={SuccessGif} alt="success" />
        <div className="success-screen__button-wrapper">
          <button
            onClick={(evt) => onCloseHandle(evt)}
            className="success-screen__button"
          >
            До головного меню
          </button>
        </div>
      </div>
    </div>
  );
  }

  return (
    <div className="success-screen-wrapper">
      <div className="success-screen">
        <h2 className="success-screen__title">Ваша заявка прийнята</h2>
        <p className="success-screen__text">
          Найближчим часом з Вами зв’яжуться.
        </p>
        <img className="success-screen_img" src={SuccessGif} alt="success" />
        <div className="success-screen__button-wrapper">
          <button
            onClick={(evt) => onCloseHandle(evt)}
            className="success-screen__button"
          >
            До головного меню
          </button>
        </div>
      </div>
    </div>
  );
}
