import React from "react";
import SuccessGif from "../../../../../assets/img/success-screen.gif";

export function SuccessPhoneChangeScreen({ onClose }) {
  const onCloseHandle = (evt) => {
    onClose(evt);
  };

  return (
    <div className="success-screen-wrapper">
      <div className="success-screen">
        <h2 className="success-screen__title">Ваш номер успішно замінено</h2>
        <img className="success-screen_img" src={SuccessGif} alt="success" />
        <div className="success-screen__button-wrapper">
          <button
            onClick={(evt) => onCloseHandle(evt)}
            className="success-screen__button"
          >
            До головного меню
          </button>
        </div>
      </div>
    </div>
  );
}
