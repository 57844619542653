import { ActionType } from "../../const/const";
import { sortVisitList } from "../../utils/utils";

export const setVisits = (data) => {
  return {
    type: ActionType.SET_VISITS,
    payload: data.sort(sortVisitList),
  };
};

export const setVisitSubmitStatus = (data) => {
  return {
    type: ActionType.SET_VISIT_FORM_SUBMIT_STATUS,
    payload: data,
  };
};
