import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo_color.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../../service/token/storage.service";
import ApiService from "../../../service/api/api.service";
import { withRouter, Redirect } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

class Header extends Component {
  state = {
    headerClass: "",
    openedNav: "",
    isLogout: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.windowOnScroll, true);
    document.addEventListener("click", (e) => this.closeNavMenu(e), true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.windowOnScroll);
    document.removeEventListener("click", this.closeNavMenu);
  }

  windowOnScroll = () => {
    if (window.scrollY > 40) {
      this.setState({ headerClass: "mini" });
    } else {
      this.setState({ headerClass: "" });
    }
  };

  openNavMenu = (name) => {
    if (this.state.openedNav === name) {
      this.setState({ openedNav: "" });

      return false;
    }

    this.setState({ openedNav: name });
  };

  closeNavMenu = (event) => {
    if (typeof event.target.className !== "object") {
      if (
        event.target.className.indexOf("nav__link--link") === -1 &&
        event.target.className.indexOf("nav__link__sub") === -1
      ) {
        this.setState({ openedNav: "" });
      }
    }
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  loadMultiprofile = (profile) => {
    ProfileService.deleteMultiProfile(this.props.userData.id);
    TokenService.removeToken();
    ApiService.removeHeader();
    TokenService.saveToken(profile.token);
    ApiService.setHeader();

    this.refreshPage();

    return <Redirect to={`/dashboard`} />;
  };

  logout = () => {
    const multyProfiles = ProfileService.getMultyProfiles();
    const nextProfile = multyProfiles.find(
      (item) => item.id !== this.props.userData.id
    );
    if (nextProfile) {
      this.loadMultiprofile(nextProfile);
      return;
    }
    if (multyProfiles.length > 0) {
      ProfileService.deleteMultiProfile(this.props.userData.id);
    }
    TokenService.removeToken();
    TokenService.removePreviosToken();
    TokenService.removeChatData();
    ApiService.removeHeader();
    UserDataService.removeUserData();

    this.setState({ isLogout: true });
  };

  setSocialNetworks = () => {
    const { data } = this.props;

    if (!data.socialNetworks) return;

    const sc = data.socialNetworks;

    return sc.map((item, index) => {
      return (
        <a key={index} className="social__link" href={item.link}>
          <svg className={`svg-${item.icon}-dark-dims`}>
            <use xlinkHref={`#${item.icon}-dark`} />
          </svg>
        </a>
      );
    });
  };

  setMenu = () => {
    const { data } = this.props;
    const { openedNav } = this.state;

    if (!data.header) return;

    const menu = JSON.parse(data.header.menu);
    let isOpen = null;

    return menu.map((item, index) => {
      isOpen = openedNav === item.label ? "open" : "";

      return (
        <div
          key={index}
          className={`nav__link ${item.items ? isOpen : ""}`}
          onClick={item.items ? () => this.openNavMenu(item.label) : null}
        >
          {item.items && (
            <div className="nav__link--link">
              {item.label}
              {item.items && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="uil uil-angle-down"
                  size="xs"
                />
              )}
            </div>
          )}
          {!item.items && <a href={item.url}>{item.label}</a>}
          {item.items && (
            <div className="nav__link__sub">
              {item.items.map((item, index) => {
                return (
                  <a key={index} href={item.url}>
                    {item.label}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { headerClass } = this.state;

    const { openMobileMenu, data } = this.props;

    if (this.state.isLogout) {
      return <Redirect to={`/login`} />;
    }

    return (
      <div className={`container-header ${headerClass}`}>
        <div className="header header__dark container-l">
          <div className="header__logo">
            <Link to="/dashboard">
              <img className="logo_color" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="mobile__menu" onClick={openMobileMenu}>
            <i></i>
            <i></i>
            <i></i>
          </div>
          {data.header && (
            <div className="header__wrapper">
              <div className="header__wrapper__about">
                <div className="header__wrapper__about__item">
                  <a href={`tel:${data.header.phone}`}>{data.header.phone}</a>
                </div>
                <div className="header__wrapper__about__item">
                  <a href={`mailto:${data.header.email}`}>
                    {data.header.email}
                  </a>
                </div>
                <div className="header__wrapper__about__item header__wrapper__about__item__button">
                  <button onClick={this.logout} className="button button__red">
                    Вийти
                  </button>
                </div>
                <div className="header__wrapper__about__item">
                  {this.setSocialNetworks()}
                </div>
              </div>
              <div className="header__wrapper__nav">{this.setMenu()}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.layouts,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(withRouter(Header));
