import React from "react";
import PropTypes from "prop-types";

export function DocumentButtonMobile({ isActive, onClick }) {
  const activeColor = "black";
  const defaultColor = "#6F8195";

  return (
    <svg
      onClick={onClick}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8 6.45C17.825 6.5 17.85 6.55 17.9 6.6C18 6.7 18 6.9 18 7V19C18 20.7 16.7 22 15 22H3C1.3 22 0 20.7 0 19V3C0 1.3 1.3 0 3 0H11C11.1 0 11.3 0 11.4 0.1C11.5 0.1 11.6 0.2 11.7 0.3L17.7 6.3C17.75 6.35 17.775 6.4 17.8 6.45ZM13 13C13.6 13 14 12.6 14 12C14 11.4 13.6 11 13 11H5C4.4 11 4 11.4 4 12C4 12.6 4.4 13 5 13H13ZM14 16C14 16.6 13.6 17 13 17H5C4.4 17 4 16.6 4 16C4 15.4 4.4 15 5 15H13C13.6 15 14 15.4 14 16ZM5 7C4.4 7 4 7.4 4 8C4 8.6 4.4 9 5 9H13C13.6 9 14 8.6 14 8C14 7.4 13.6 7 13 7H5Z"
        fill={isActive ? activeColor : defaultColor}
      />
    </svg>
  );
}

DocumentButtonMobile.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};
