import { useRef, useState } from "react";
import Supercluster from "../utils/supercluster";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { dequal } from "dequal";

const useSupercluster = ({ points, bounds, zoom, options }) => {
  const superclusterRef = useRef();
  const pointsRef = useRef();
  const [clusters, setClusters] = useState([]);
  const zoomInt = Math.round(zoom);

  useDeepCompareEffectNoCheck(() => {
    if (
      !superclusterRef.current ||
      !dequal(pointsRef.current, points) ||
      !dequal(
        (
          superclusterRef.current &
          {
            options,
          }
        ).options,
        options
      )
    ) {
      superclusterRef.current = new Supercluster(options);

      superclusterRef.current.load(points);
    }

    if (bounds) {
      const clusterSuper = new Supercluster(options);
      clusterSuper.load(points);

      setClusters(superclusterRef.current.getClusters(bounds, zoomInt));
    }

    pointsRef.current = points;
  }, [points, bounds, zoomInt, options]);

  return { clusters, supercluster: superclusterRef.current };
};

export default useSupercluster;
