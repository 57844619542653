import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { isTel, transformTelNumber } from "../../validator/tel.validator";
import { trackPromise } from "react-promise-tracker";
import { TokenService } from "../../../service/token/storage.service";
import ApiService from "../../../service/api/api.service";
import { updateUserTel } from "../../../actions/user/updateUserTel";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { SuccessPhoneChangeScreen } from "../../../components/dashboard/layouts/mobile-dashboard/personal-info/successPhoneChangeScreen";
import { ErrorSubmitScreen } from "../../../components/dashboard/layouts/mobile-dashboard/personal-info/errorPhoneChangeScreen";

class ModalChangeTel extends Component {
  state = {
    tel: "",
    sms: "",
    isSmsFiedShow: false,
    isFormError: false,
    telErrorText: "",
    smsErrorText: "",
    isSubmitSuccess: false,
    isSubmitError: false,
  };

  sendSmsCode = () => {
    if (this.state.tel.length !== 0) {
      if (isTel(this.state.tel)) {
        let data = {
          query: `mutation {
                                user(access_token: "${TokenService.getToken()}") {
                                    update(phone: "${transformTelNumber(
                                      this.state.tel
                                    )}") {
                                        success,
                                        message
                                    }
                                }
                            }`,
        };

        trackPromise(
          ApiService.post("/cabinet", data)
            .then((response) => {
              const { data } = response.data;
              const { showToast } = this.props;

              if (data.user.update.success) {
                if (!this.props.isMobile) {
                  showToast("success", data.user.update.message);
                }

                this.showSmsField();
              } else {
                this.setState({
                  isFormError: true,
                  telErrorText: data.user.update.message,
                });
              }
            })
            .catch((err) => console.error(err))
        );
      } else {
        this.setState({
          isFormError: true,
          telErrorText: "Невірно вказаний номер",
        });
      }
    } else {
      this.setState({
        isFormError: true,
        telErrorText: "Поле обов'язкове для заповнення",
      });
    }
  };

  changeTelNumber = () => {
    if (this.state.tel.length !== 0 && this.state.sms.length !== 0) {
      if (isTel(this.state.tel)) {
        let data = {
          query: `mutation {
                                user(access_token: "${TokenService.getToken()}") {
                                    update(phone: "${transformTelNumber(
                                      this.state.tel
                                    )}" code: "${this.state.sms}") {
                                        success,
                                        message,
                                        phone
                                    }
                                }
                            }`,
        };

        trackPromise(
          ApiService.post("/cabinet", data)
            .then((response) => {
              const { data } = response.data;

              const { showToast, close, updateUserTel } = this.props;
              const userData = this.props.data;
              const fields = {
                username: userData.username,
                id: userData.id,
                phone: transformTelNumber(this.state.tel),
                birth_date: userData.birth_date,
                address: userData.address,
                polis: userData.polis,
                card_number: userData.card_number,
                image: userData.image,
                messages: userData.messages,
                insurance_information: userData.insurance_information,
                broker: userData.broker,
                files: userData.files,
                sharedFiles: userData.sharedFiles,
              };

              if (data.user.update.success) {
                updateUserTel(transformTelNumber(this.state.tel), fields);
                if (!this.props.isMobile) {
                  showToast("success", data.user.update.message);
                }

                this.setState({
                  tel: "",
                  sms: "",
                  isSmsFiedShow: false,
                  isFormError: false,
                  telErrorText: "",
                  smsErrorText: "",
                  isSubmitSuccess: true,
                });
                this.refs.telInput.value = "";
                if (!this.props.isMobile) {
                  close();
                }
              } else {
                this.setState({
                  isFormError: true,
                  telErrorText: "",
                  smsErrorText: data.user.update.message,
                  isSubmitError: true,
                  isSubmitSuccess: false,
                });
              }

              // if (data.user.update.phone !== null) {

              // } else {
              //   this.setState({ isSubmitError: true });
              // }
            })
            .catch((err) => {
              this.setState({
                isSubmitError: true,
              });
              console.error(err);
            })
        );
      } else {
        this.setState({
          isFormError: true,
          telErrorText: "Невірно вказаний номер",
        });
      }
    } else {
      this.setState({
        isFormError: true,
        telErrorText: "Поле обов'язкове для заповнення",
        smsErrorText: "Поле обов'язкове для заповнення",
      });
    }
  };

  showSmsField = () => {
    this.setState({ isSmsFiedShow: true });
  };

  clearError = () => {
    this.setState({
      telErrorText: "",
      smsErrorText: "",
    });
  };

  handleInput = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  onFormCloseHandler = (evt) => {
    evt.preventDefault();
    if (this.state.isSmsFiedShow) {
      this.setState({
        isSmsFiedShow: false,
      });

      return;
    }
    if (this.state.isSubmitSuccess) {
      this.setState({
        isSubmitSuccess: false,
      });
    }

    this.props.closeTelForm();
  };

  onCloseErrorScreen = () => {
    this.setState({
      isSmsFiedShow: false,
      isSubmitError: false,
    });
  };

  render() {
    const { active, close, isMobile = false } = this.props;

    const {
      isSmsFiedShow,
      isFormError,
      telErrorText,
      smsErrorText,
      isSubmitSuccess,
      isSubmitError,
    } = this.state;

    const isFormHasError = isFormError ? "error" : "";

    return (
      <>
        {!isMobile && (
          <div className={`modal__wrap call ${active}`}>
            <div className="modal__call">
              <div className="modal__call__head">
                <div className="caption">Зміна номера телефону</div>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="close__modal"
                  onClick={close}
                />
              </div>
              <form className={`modal__call__body ${isFormHasError}`}>
                <div className="modal__call__body__row">
                  <label>Номер телефону</label>
                  <InputMask
                    name="tel"
                    type="tel"
                    placeholder="+38"
                    mask="+38(999)-999-99-99"
                    required
                    onFocus={this.clearError}
                    onChange={this.handleInput}
                    ref="telInput"
                  />
                  <div className="help__error">{telErrorText}</div>
                </div>
                {!isSmsFiedShow && (
                  <button
                    type="button"
                    className="button button__success"
                    onClick={this.sendSmsCode}
                  >
                    Відправити SMS
                  </button>
                )}
                {isSmsFiedShow && (
                  <div>
                    <div>
                      <div className="link__other__row mb20">
                        <span className="pointer" onClick={this.sendSmsCode}>
                          Відправити SMS повторно
                        </span>
                      </div>
                    </div>
                    <div className="modal__call__body__row-mobile">
                      <label>Введіть код з SMS:</label>
                      <input
                        type="password"
                        name="sms"
                        onFocus={this.clearError}
                        onChange={this.handleInput}
                      />
                      <div className="help__error">{smsErrorText}</div>
                    </div>
                    <button
                      type="button"
                      onClick={this.changeTelNumber}
                      className="button button__success"
                    >
                      Змінити номер
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}

        {isMobile && (
          <div className={`modal__wrap-mobile`}>
            <div className="modal__call__head-mobile">
              <button
                onClick={(evt) => this.onFormCloseHandler(evt)}
                className="tel-form-back-button-mobile"
              >
                <svg
                  width="27"
                  height="17"
                  viewBox="0 0 27 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8.5L26 8.5"
                    stroke="#519025"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M1 8.50002L8.40592 1"
                    stroke="#519025"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M1 8.5L8.40592 16"
                    stroke="#519025"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <div className="caption-mobile">Зміна номера телефону</div>
            </div>
            <form className={`modal__call__body-mobile ${isFormHasError}`}>
              <div className="modal__call__body__row-mobile">
                <label>Виберіть Номер телефону:</label>
                <InputMask
                  name="tel"
                  type="tel"
                  placeholder="+38"
                  mask="+38(999)-999-99-99"
                  required
                  onFocus={this.clearError}
                  onChange={this.handleInput}
                  ref="telInput"
                  disabled={isSmsFiedShow}
                />
                <div className="help__error">{telErrorText}</div>
              </div>
              {!isSmsFiedShow && (
                <div className="madal-change-tel__button-wrapper">
                  <button
                    type="button"
                    className="button button__success-mobile"
                    onClick={this.sendSmsCode}
                  >
                    Відправити SMS
                  </button>
                </div>
              )}
              {isSmsFiedShow && (
                <div className="modal__call__sms-input-mobile__wrapper">
                  <div className="modal__call__sms-input-mobile">
                    <label>Введіть код з SMS:</label>
                    <input
                      type="password"
                      name="sms"
                      onFocus={this.clearError}
                      onChange={this.handleInput}
                    />
                    <div className="help__error">{smsErrorText}</div>
                  </div>
                  <div className="madal-change-tel__button-wrapper">
                    <button
                      type="button"
                      onClick={this.changeTelNumber}
                      className="button button__success-mobile"
                    >
                      Змінити номер
                    </button>
                  </div>
                </div>
              )}
            </form>
            {isSubmitSuccess && (
              <SuccessPhoneChangeScreen
                onClose={(evt) => this.onFormCloseHandler(evt)}
              />
            )}
            {isSubmitError && (
              <ErrorSubmitScreen onClose={() => this.onCloseErrorScreen()} />
            )}
          </div>
        )}
      </>
    );
  }
}

function matchDispatchToProps(dispath) {
  return bindActionCreators(
    {
      updateUserTel: updateUserTel,
    },
    dispath
  );
}

function mapStateToProps(state) {
  return {
    data: state.user.userData,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(ModalChangeTel);
