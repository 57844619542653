import React, { useState, useEffect } from "react";
import "./refund-information.scss";
import connect from "react-redux/es/connect/connect";
import { setMapFilterState } from "../../../../../actions/control/setMapFilterState";
import { setIsOpenCarPage } from "../../../../../actions/control/setIsOpenCarPage";
import { setIsOpenGreenCardPage } from "../../../../../actions/control/setIsOpenGreenCard";
import { setIsOpenPlanePage } from "../../../../../actions/control/setIsOpenPlainPage";

function RefundInformation({ setMapFilter, notPaidTickets, isOpenCar, isOpenPlain, isOpenGreenCard, setOpenCalculatorPage }) {
  const [activePage, setActivePage] = useState('travel')

  const onVisitButtonHandle = (evt, page) => {
    evt.preventDefault();
    setActivePage(page)
  };

  useEffect(() => {
    if (isOpenCar) {
      setActivePage('car')
    }
    if (isOpenPlain) {
      setActivePage('travel')
    }
    if(isOpenGreenCard) {
      setActivePage('green')
    }
    setOpenCalculatorPage(isOpenCar, isOpenPlain, isOpenGreenCard)
  },[isOpenCar, isOpenPlain, isOpenGreenCard])

  return (
    <div className="calculate-container">
      <div className="user-calculate-buttons">
      <div className="user-history-mobile__sticky-block">
        <h2 className="user-history-mobile__title">Калькулятор Страхування</h2>
        </div>
      </div>
      <div className="user-history-mobile__button-wrapper">
      <button
            onClick={(evt) => onVisitButtonHandle(evt, 'car')}
            className={`user-history-mobile__button ${
              activePage === 'car'
                ? "user-history-mobile__button--active"
                : ""
            }`}
          >
            Автоцивілка та ДЦВ
          </button>
        <button
            onClick={(evt) => onVisitButtonHandle(evt, 'travel')}
            className={`user-history-mobile__button ${
              activePage === 'travel'
                ? "user-history-mobile__button--active"
                : ""
            }`}
          >
            Туристичне страхування
          </button>
          <button
            onClick={(evt) => onVisitButtonHandle(evt, 'green')}
            className={`user-history-mobile__button ${
              activePage === 'green' 
              ? "user-history-mobile__button--active" 
              : ""
            }`}
          >
            Зелена карта
          </button>
        </div>

        {activePage === 'travel' && (
          <iframe id='travel-frame' className="polis-iframe" src="https://tbt-broker.polis.ua/uk/travel"></iframe>
        )}

{activePage === 'car' && (
          <iframe id="car-frame" className="polis-iframe" src="https://tbt-broker.polis.ua/"></iframe>
        )}

{activePage === 'green' && (
          <iframe id='green-frame' className="polis-iframe" src="https://tbt-broker.polis.ua/uk/greencard"></iframe>
        )}


        {/* <section className="refund-info">
          <h2 className="refund-info__title">Калькулятор Страхування</h2>
         
        </section> */}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state.user.userData,
    notPaidTickets: state.refund.notPaidTickets,
    isOpenCar: state.control.isOpenCar,
    isOpenPlain: state.control.isOpenPlain,
    isOpenGreenCard: state.control.isOpenGreenCard,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMapFilter(value) {
    dispatch(setMapFilterState(value));
  },
  setOpenCalculatorPage(isOpenCar, isOpenPlain, isOpenGreenCard) {
    if (isOpenCar) {
      dispatch(setIsOpenCarPage(false))
    }
    if (isOpenPlain) {
      dispatch(setIsOpenPlanePage(false))
    }
    if (isOpenGreenCard) {
      dispatch(setIsOpenGreenCardPage(false))
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundInformation);
