import React from 'react';
import emailIcon from '../../../assets/img/Caller-1.svg';
import phoneIcon from '../../../assets/img/Caller.svg';
import connect from "react-redux/es/connect/connect";
import { transformTelNumberReverse } from '../../validator/tel.validator';

function BrokerInfo({ data }) {
        const brokerData = data.broker;
        
        return (
            <div className="persolan-area__conteiner broker__area">
                {
                    brokerData &&
                
                    <div className="personal__info personal__info__mobile">
                        <div className="top__header">
                            <div className="caption">Контактна особа брокера</div>
                        </div>
                        <div className="row align-center">
                            <div className="personal__info__avatar">
                                <img src={brokerData.imageUrl} alt="broker"/>
                            </div>
                            {
                                brokerData.name ?
                                <div className="large__18 text ml10">{brokerData.name}</div>
                                :
                                <div className="large__18 text ml10">Відсутній</div>
                            }    
                        </div>
                        <div className="personal__info__desc">
                            <div className="row align-center space-between">
                                <div className="column">
                                    <span className="label">Номер телефону:</span>
                                    {
                                        brokerData.phone ?
                                        <div className="desc phone">{transformTelNumberReverse(brokerData.phone)}</div>
                                        :
                                        <div className="desc phone">-</div>
                                    }
                                    
                                </div>
                                {
                                    brokerData.phone &&
                                    <a href={`tel:${transformTelNumberReverse(brokerData.phone)}`}>
                                        <img src={phoneIcon} className="scale-icon" alt="tel"/>
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="personal__info__desc">
                            <div className="row align-center space-between">
                                <div className="column">
                                    <span className="label">Email:</span>
                                    {
                                        brokerData.email ?
                                        <div className="desc email-adress">
                                            {brokerData.email}
                                        </div>
                                        :
                                        <div className="desc email-adress">
                                            -
                                        </div>
                                    }
          
                                </div>
                                {
                                    brokerData.email &&
                                    <a href={`mailto:${brokerData.email}`}>
                                        <img src={emailIcon} className="scale-icon" alt="email"/>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
}

function mapStateToProps(state) {
    return {
        data: state.user.userData,
    }
}

export default connect(mapStateToProps)(BrokerInfo);