import React from "react";
import ApiService from "../../../service/api/api.service";
import {
  TokenService,
  ProfileService,
  UserDataService,
} from "../../../service/token/storage.service";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsAddingProfile } from "../../../actions/user/setIsAddingProfile";
import { addPreviosToken } from "../../../actions/user/addPreviosToken";

const AddProfileButton = ({ isMobile }) => {
  const { id, username, image } = useSelector((state) => state.user.userData);
  const history = useHistory();
  const dispath = useDispatch();
  const logout = () => {
    const token = TokenService.getToken();
    TokenService.setPreviosToken();
    ProfileService.addCurrentProfile({ id, userName: username, image });

    TokenService.removeToken();
    ApiService.removeHeader();
    UserDataService.removeUserData();
    TokenService.removeChatData();
    history.push("/login");
    window.location.reload(false);
  };

  return (
    <div
      onClick={() => {
        logout();
      }}
      className={
        isMobile
          ? "multilogin-menu__add-proffile-button-mobile"
          : "multilogin-menu__add-proffile-button"
      }
    >
      + Додати інший аккаунт
    </div>
  );
};

export default AddProfileButton;
