import React, { useState, useEffect } from "react";
import { MapButtonMobile } from "./mobile-buttons/mapButtonMobile";
import { DocumentButtonMobile } from "./mobile-buttons/documentButton";
import { HomeButtonMobile } from "./mobile-buttons/homeButtonMobile";
import { PersonalButtonMobile } from "./mobile-buttons/personalButtonMobile";
import { MoneyButtonMobile } from "./mobile-buttons/moneyButtonMobile";
import PersonalInfo from "./personal-info/personalInfo";
import MapContainer from "../../main/map/mapContainer";
import RefundInformation from "./refund-information/refundInformation";
import MainInformation from "./mainInformation/mainInformation";
import UserHistoryMobile from "./user-history/userHistoryMobile";
import "./mobile-dashboard.scss";
import connect from "react-redux/es/connect/connect";
import Chat from "../../main/chat";

const MOBILE_MENU = {
  MAP: "map",
  DOCUMENT: "document",
  HOME: "home",
  MONEY: "money",
  PERSONAL: "personal",
};

export function MobileDashBoard({
  isMapFilterOpen,
  isOpenCar,
  isOpenPlain,
  isOpenGreenCard,
  showToast,
}) {
  const [currnetMenuItem, setCurrentMenuItem] = useState(MOBILE_MENU.HOME);

  const onClockMenuButtonHandle = (buttonName) => {
    switch (buttonName) {
      case MOBILE_MENU.MAP:
        setCurrentMenuItem(MOBILE_MENU.MAP);
        break;
      case MOBILE_MENU.DOCUMENT:
        setCurrentMenuItem(MOBILE_MENU.DOCUMENT);
        break;
      case MOBILE_MENU.HOME:
        setCurrentMenuItem(MOBILE_MENU.HOME);
        break;
      case MOBILE_MENU.MONEY:
        setCurrentMenuItem(MOBILE_MENU.MONEY);
        break;
      case MOBILE_MENU.PERSONAL:
        setCurrentMenuItem(MOBILE_MENU.PERSONAL);
        break;
      default:
        setCurrentMenuItem(MOBILE_MENU.HOME);
        break;
    }
  };

  useEffect(() => {
    if (isOpenCar || isOpenPlain || isOpenGreenCard) {
      setCurrentMenuItem(MOBILE_MENU.MONEY);
      onClockMenuButtonHandle(MOBILE_MENU.MONEY);
    }
  }, [isOpenCar, isOpenPlain, isOpenGreenCard]);

  return (
    <section className="mobile-dashboard">
      {currnetMenuItem === MOBILE_MENU.PERSONAL && <PersonalInfo />}
      {currnetMenuItem === MOBILE_MENU.MAP && <MapContainer isMobile={true} />}
      {currnetMenuItem === MOBILE_MENU.DOCUMENT && <UserHistoryMobile />}
      {currnetMenuItem === MOBILE_MENU.MONEY && <RefundInformation />}
      {currnetMenuItem === MOBILE_MENU.HOME && (
        <MainInformation showToast={showToast} />
      )}
      <div
        className={`menu-items ${isMapFilterOpen ? "menu-items--hidden" : ""}`}
      >
        <MapButtonMobile
          onClick={() => onClockMenuButtonHandle(MOBILE_MENU.MAP)}
          isActive={currnetMenuItem === MOBILE_MENU.MAP ? true : false}
        />
        <DocumentButtonMobile
          onClick={() => onClockMenuButtonHandle(MOBILE_MENU.DOCUMENT)}
          isActive={currnetMenuItem === MOBILE_MENU.DOCUMENT ? true : false}
        />
        <HomeButtonMobile
          onClick={() => onClockMenuButtonHandle(MOBILE_MENU.HOME)}
          isActive={currnetMenuItem === MOBILE_MENU.HOME ? true : false}
        />
        <MoneyButtonMobile
          onClick={() => onClockMenuButtonHandle(MOBILE_MENU.MONEY)}
          isActive={currnetMenuItem === MOBILE_MENU.MONEY ? true : false}
        />
        <PersonalButtonMobile
          onClick={() => onClockMenuButtonHandle(MOBILE_MENU.PERSONAL)}
          isActive={currnetMenuItem === MOBILE_MENU.PERSONAL ? true : false}
        />
      </div>
      {/* <Chat isMobile={true} chatClass={"active"} isChatMobileOpen={true} /> */}
    </section>
  );
}

const mapStateToProps = (state) => ({
  isMapFilterOpen: state.control.isMapFilterOpen,
  isOpenCar: state.control.isOpenCar,
  isOpenPlain: state.control.isOpenPlain,
  isOpenGreenCard: state.control.isOpenGreenCard,
});

export default connect(mapStateToProps, null)(MobileDashBoard);
