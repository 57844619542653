import React, { useRef } from "react";
import { getFileImageMobile } from "../../../../utils/utils";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const onItemClickHandler = (url) => {
  window.open(url, "_blank");
};

export function FileNameBlock({ filesArray, closeFileInformation }) {
  // const onClickOutsideElement = (evt) => {
  //   if (fileListRef.current.contains(evt.target)) {
  //     return;
  //   }
  //   setFilesInformation({
  //     fileType: null,
  //     rowIndex: null,
  //   });
  // };

  // useEffect(() => {
  //   document.addEventListener("keydown", escFunction, false);
  //   //document.addEventListener("mousedown", onClickOutsideElement, false);

  //   return () => {
  //     document.removeEventListener("keydown", escFunction, false);
  //   };
  // });

  const filesInfoRef = useRef();

  useOutsideClick(filesInfoRef, () => {
    closeFileInformation();
  });

  return (
    <div ref={filesInfoRef} className="refund-list__file-name-block__files">
      <ul className="refund-list__file-block">
        {filesArray.length > 0 &&
          filesArray.map((item) => {
            return (
              <li
                onClick={() => onItemClickHandler(item)}
                className="refund-list__file-block-item"
                key={item}
                style={{ justifyContent: "space-between" }}
              >
                <img
                  src={getFileImageMobile(item.split(".").pop())}
                  alt="file-type"
                />
                <span className="refund-list__file-block-item-name">
                  Переглянути
                </span>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0C4 0 1.5 1.778 0 4C1.5 6.2215 4 8 7 8C10 8 12.5 6.2215 14 4C12.5 1.778 10 0 7 0ZM3.9325 6.2365C2.925 5.7215 2.001 4.964 1.238 4C2.001 3.036 2.925 2.278 3.9325 1.7635C3.474 2.3915 3.2 3.163 3.2 4C3.2 4.837 3.474 5.6085 3.9325 6.2365ZM7 7C5.3455 7 4 5.6545 4 4C4 2.3455 5.3455 1 7 1C8.6545 1 10 2.3455 10 4C10 5.6545 8.6545 7 7 7ZM10.0675 6.2365C10.526 5.6085 10.8 4.837 10.8 4C10.8 3.163 10.526 2.3915 10.0675 1.7635C11.0755 2.278 11.999 3.0355 12.762 4C11.999 4.964 11.075 5.7215 10.0675 6.2365Z"
                    fill="#AFAFAF"
                  />
                  <path
                    d="M7 2.24951C6.733 2.24951 6.483 2.31401 6.2565 2.42101C6.5105 2.51151 6.694 2.75151 6.694 3.03701C6.694 3.39951 6.4005 3.69351 6.0375 3.69351C5.752 3.69351 5.512 3.51051 5.4215 3.25601C5.3145 3.48251 5.25 3.73251 5.25 3.99951C5.25 4.96651 6.0335 5.74951 7 5.74951C7.967 5.74951 8.75 4.96651 8.75 3.99951C8.75 3.03301 7.967 2.24951 7 2.24951Z"
                    fill="#AFAFAF"
                  />
                </svg>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
