import React, { useState } from 'react';
import { connect } from "react-redux";
import { setIsOpenCarPage } from '../../../../../actions/control/setIsOpenCarPage';
import { setIsOpenPlanePage } from '../../../../../actions/control/setIsOpenPlainPage';
import { setIsOpenGreenCardPage } from '../../../../../actions/control/setIsOpenGreenCard';
import carImage from '../../../../../assets/img/carImage.png'
import planeImage from "../../../../../assets/img/planeImage.png"
import greenCard from "../../../../../assets/img/greenCard.png"


const ModalWindow = ({setOpenCalculatorPage, isCloseModal}) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    isCloseModal()
  };

  const onOpenOtherPage = (ev,value) => {
    ev.preventDefault()
    setIsOpen(false);
    setOpenCalculatorPage(value)
  }

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.4">
<path d="M19.5 6.5L6.5 19.5M19.5 19.5L6.5 6.50001" stroke="#111111" strokeWidth="2.16667" strokeLinecap="round"/>
</g>
</svg>
        </span>
        <h2 className='modal-content-title'>Застрахуй своє майно та забезпеч собі спокій!</h2>
        <p className='modal-content-text'>Обери надійного страхового партнера, який зробить твоє життя безпечнішим.</p>
      <div className='modal-content-button-container'>
      <button onClick={(evt) => onOpenOtherPage(evt,'avto')} className='modal-content-buttons'>
<img src={carImage} alt="" className="modal-content-svg"/>
      Автоцивілка та ДЦВ
        </button>
        <button onClick={(evt) => onOpenOtherPage(evt,'tourist')} className='modal-content-buttons'>
 <img src={planeImage} alt="" className="modal-content-svg"/>
      Туристичне страхування
        </button>
        <button onClick={(evt) => onOpenOtherPage(evt,'greenCard')} className='modal-content-buttons'>
 <img src={greenCard} alt="" className="modal-content-svg"/>
      Зелена карта
        </button>
      </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setOpenCalculatorPage(value) {
    if (value === 'avto') {
      dispatch(setIsOpenCarPage(true))
    } 
    if (value === 'tourist') {
      dispatch(setIsOpenPlanePage(true))
    }
    if (value === 'greenCard') {
      dispatch(setIsOpenGreenCardPage(true))
    }
  },
});

export default connect(null, mapDispatchToProps)(ModalWindow); ;