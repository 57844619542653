import React, { useState } from "react";
import googlePlayImage from "../../../assets/img/google-play.png";
import appStoreimage from "../../../assets/img/app-store.png";
import phonePhotoDesktop from "../../../assets/img/phone-photo.png";
import phonePhotoMobile from "../../../assets/img/phone-pro-12.png";
import { isIos } from "../../../utils/utils";

import "./modals.scss";

const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.tbt_broker.cabinet.twa";

const APP_STORE_LINK =
  "https://apps.apple.com/us/app/tbt-%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B8%D0%B9-%D0%B1%D1%80%D0%BE%D0%BA%D0%B5%D1%80/id1601665490";

let isIOS = isIos();

export function AppLoadOffer({ onClose, isMobile = false }) {
  const onCloseHandler = (evt) => {
    evt.preventDefault();
    onClose();
  };

  const onClickStoreLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {!isMobile && (
        <div className="app-offer__wraper">
          <div className="app-offer__positioner">
            <div className="app-offer__main-block">
              <div className="app-offer__main-block-info">
                <div className="app-offer__main-social-links">
                  <button
                    onClick={(evt) => onCloseHandler(evt)}
                    className="app-offer__close-button"
                  ></button>
                  <h3 className="app-offer__title">
                    Зручний мобільний додаток
                  </h3>
                  <p className="app-offer__description">
                    Завантажуйте на Android та iPhone
                  </p>
                  <div className="app-offer__link-block">
                    <div
                      onClick={() => onClickStoreLink(APP_STORE_LINK)}
                      className="app-offer__link-block-item"
                    >
                      <img src={appStoreimage} alt="app-store" />
                    </div>
                    <div
                      onClick={() => onClickStoreLink(GOOGLE_PLAY_LINK)}
                      className="app-offer__link-block-item"
                    >
                      <img src={googlePlayImage} alt="google-play" />
                    </div>
                  </div>
                </div>
                <div className="app-offer__main-block-decor-image">
                  <img src={phonePhotoDesktop} alt="phone-photo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="app-offer__wraper-mobile">
          <div className="app-offer__main-block">
            <div className="app-offer__main-block-info-mobile">
              <div className="app-offer__main-social-links-mobile">
                <div className="app-offer__main-social-links-wrapper-mobile">
                  <button
                    onClick={(evt) => onCloseHandler(evt)}
                    className="app-offer__close-button"
                  ></button>
                  <h3
                    className={`app-offer__title-mobile app-offer__title-mobile--android `}
                  >
                    Зручний мобільний додаток
                  </h3>

                  {!isIOS && (
                    <div className="app-offer__description-mobile">
                      <span>Завантажуйте на Android</span>
                    </div>
                  )}
                  {isIOS && (
                    <div className="app-offer__description-mobile">
                      <span>Завантажуйте на IOS</span>
                    </div>
                  )}
                  <div className="app-offer__link-block">
                    {isIOS && (
                      <div
                        onClick={() => onClickStoreLink(APP_STORE_LINK)}
                        className="app-offer__link-block-item"
                      >
                        <img src={appStoreimage} alt="app-store" />
                      </div>
                    )}
                    {!isIOS && (
                      <div
                        onClick={() => onClickStoreLink(GOOGLE_PLAY_LINK)}
                        className="app-offer__link-block-item"
                      >
                        <img src={googlePlayImage} alt="google-play" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="app-offer__main-block-decor-image-mobile">
                <img src={phonePhotoMobile} alt="phone-photo" height="230" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
