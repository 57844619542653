import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import pdfFile from "../../../assets/img/pdfFile.svg";
import InsuranceCard from "../../common/InsuranceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

class InsuranceInfo extends Component {
  state = { isInsuranceCardOpen: false };
  setFiles = () => {
    const files = this.props.data.files;

    if (!files) return;

    return files.map((item, index) => {
      return (
        <div key={index} className="file">
          <div className="row align-center blured__container">
            <img src={pdfFile} alt="file" />
            <div className="name ml10">{item.name}</div>
          </div>
          {/* eslint-disable-next-line */}
          <a href={item.url} rel="noopener noreferrer" target="_blank" />
          <div className="file__onhover">
            <div className="row centered">
              <FontAwesomeIcon icon={faEye} color="#434343" />
              <span className="text">Перегляд </span>
            </div>
          </div>
        </div>
      );
    });
  };

  setSharedFiles = () => {
    const files = this.props.data.sharedFiles;

    if (!files) return;

    return files.map((item, index) => {
      return (
        <div key={index} className="file">
          <div className="row align-center blured__container">
            <img src={pdfFile} alt="file" />
            <div className="name ml10">{item.name}</div>
          </div>
          {/* eslint-disable-next-line */}
          <a href={item.url} rel="noopener noreferrer" target="_blank" />
          <div className="file__onhover">
            <div className="row centered">
              <FontAwesomeIcon icon={faEye} color="#434343" />
              <span className="text">Перегляд </span>
            </div>
          </div>
        </div>
      );
    });
  };

  onClickInsuranceCardHandler() {
    this.setState({ isInsuranceCardOpen: !this.state.isInsuranceCardOpen });
  }

  render() {
    const data = this.props.data;
    const insuranceData = data.insurance_information;
    const brokerData = data.broker;

    return (
      <div className="personal-area__tabs__container">
        {insuranceData && (
          <div>
            <div className="persolan-area__conteiner persolan-area__conteiner__tabs active">
              <div className="persolan-area__conteiner__row">
                <div className="large__18 text">Програма страхування</div>
                <div
                  onClick={() => this.onClickInsuranceCardHandler()}
                  className="persolan-area__conteiner__show-insurance-button"
                >
                  <svg
                    width="29"
                    height="20"
                    viewBox="0 0 29 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.11 1H2.89C1.84618 1 1 1.84618 1 2.89V17.11C1 18.1538 1.84618 19 2.89 19H26.11C27.1538 19 28 18.1538 28 17.11V2.89C28 1.84618 27.1538 1 26.11 1Z"
                      stroke="#519025"
                      strokeWidth="1.22727"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.27344 14.9092H8.36435"
                      stroke="#519025"
                      strokeWidth="1.22727"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.6367 14.9092H15.7276"
                      stroke="#519025"
                      strokeWidth="1.22727"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 14.9092H23.9091"
                      stroke="#519025"
                      strokeWidth="1.22727"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21.8638 10.0001C22.9935 10.0001 23.9093 9.08431 23.9093 7.95463C23.9093 6.82496 22.9935 5.90918 21.8638 5.90918C20.7341 5.90918 19.8184 6.82496 19.8184 7.95463C19.8184 9.08431 20.7341 10.0001 21.8638 10.0001Z"
                      stroke="#519025"
                      strokeWidth="1.22727"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  <span>Показати карту</span>
                </div>
              </div>
              <div className="persolan-area__conteiner__wrap">
                <div className="personal__info">
                  <div className="personal__info__desc">
                    <span className="label">Проект</span>
                    <div className="desc sum">{insuranceData.project}</div>
                  </div>
                  <div className="personal__info__desc">
                    <span className="label">Страхова програма</span>
                    <div className="desc">
                      {insuranceData.insurance_program}
                    </div>
                  </div>
                  <div className="personal__info__desc">
                    <span className="label">Менеджер</span>
                    <div className="desc maneger">{brokerData.name}</div>
                  </div>
                </div>
                <div className="personal__info">
                  <div className="personal__info__desc">
                    <span className="label">Страхувальник</span>
                    <div className="desc">{insuranceData.insurer}</div>
                  </div>
                  <div className="personal__info__desc">
                    <span className="label">Страхова компанія</span>
                    <div className="desc company">
                      {insuranceData.insurance_company}
                    </div>
                  </div>
                </div>
                <div className="personal__info">
                  <div className="personal__info__desc">
                    <span className="label">Дата початку страхування</span>
                    <div className="desc date">
                      {insuranceData.active_contract_from}
                    </div>
                  </div>
                  <div className="personal__info__desc">
                    <span className="label">Дата кінця страхування</span>
                    <div className="desc date__update">
                      {insuranceData.active_contract_until}
                    </div>
                  </div>
                </div>
              </div>
              <div className="personal-area__container__devider" />
              <div className="personal-area__container__file__container">
                <div className="large__18 text mb20">
                  Загальні документи страхової програми
                </div>
                <div className="personal-area__container__file__container__wrapper">
                  {this.setSharedFiles()}
                </div>
              </div>
              <div className="personal-area__container__file__container mt20">
                <div className="large__18 text mb20">
                  Індивідуальні документи страхової програми
                </div>
                <div className="personal-area__container__file__container__wrapper">
                  {this.setFiles()}
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.isInsuranceCardOpen && (
          <div
            className={`main-information-mobile-client-card__container-descktop `}
          >
            <InsuranceCard
              onClickHandler={() => this.onClickInsuranceCardHandler()}
              isRotate={false}
              isDesckTop={true}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.user.userData,
  };
}

export default connect(mapStateToProps)(InsuranceInfo);
