import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "../../../../hooks/useSuperCluster.js";
import { connect } from "react-redux";
import { MarkerInfo } from "./markerInfo";

const Marker = ({ children }) => children;

const DEFAULT_MAP_CENTER = {
  lat: 48.4536,
  lng: 31.0,
};

export function MapNew({ currentLpu, userLocation, isMobile = false, openAppoitDoctorPage = () => {}, setMedicalInfo = () => {} }) {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [openedMarkerId, setOpenedMarkerId] = useState(null);
  const [openedMarker, setOpenedMarker] = useState(null);
  const [mapCenter, setMapCenter] = useState(DEFAULT_MAP_CENTER);

  let points = currentLpu.map((item, index) => ({
    type: "Feature",
    properties: {
      cluster: false,
      pointId: index,
      iconUrl: item.icon,
      name: item.name,
      adress: item.adress,
      coverage: item.coverage,
      category_id: item.category_id,
      specialization: item.specialization
    },
    geometry: {
      type: "Point",
      coordinates: [item.position.lng, item.position.lat],
    },
  }));

    const superClusterObject = useSupercluster({
      points,
      bounds,
      zoom,
    });

  const { clusters, supercluster } = superClusterObject;

  const onClicMarkerHandler = (id, lat, lng, marker) => {
    if (openedMarkerId === id) {
      return;
    }
    setTimeout(() => {
      setOpenedMarkerId(id);
      setOpenedMarker(marker);
      setMedicalInfo(marker)
    }, 50);

    setMapCenter({
      lat: lat,
      lng: lng,
    });
  };

  const onMapClickHandler = (evt) => {
    if (openedMarkerId !== null) {
      setOpenedMarkerId(null);
      setOpenedMarker(null);
      setMedicalInfo(null)
    }
  };

  const getCoordinates = () => {
    if (userLocation.latitude && userLocation.longitude) {
      mapRef.current.setZoom(15);
      mapRef.current.panTo({ lat: userLocation.latitude, lng: userLocation.longitude });
    }
  }

  useEffect(() => {
    if (currentLpu.length === 1) {
      setMapCenter({
        lat: currentLpu[0].position.lat,
        lng: currentLpu[0].position.lng,
      });
    }
  }, [currentLpu, setMapCenter]);

  return (
    <>
      <div
        style={{ height: `${isMobile ? "100vh" : "490px"} `, width: "100%", position: 'relative'}}
      >
         <button onClick={() => {getCoordinates()}} className="get-new-geolocation-button">
          <div className="button-icon-not-have-geolocation-bg" 
          style={userLocation.latitude && userLocation.longitude ? 
          {background: 'rgba(0, 0, 254, 0.4)'} : {background: 'rgba(254, 0, 0, 0.4)'}}>
            <div className="button-icon-have-geolocation-bg" 
            style={userLocation.latitude && userLocation.longitude ? 
            {background: 'rgba(0, 0, 254, 1)'} : {background: 'rgba(254, 0, 0, 1)'}}></div>
          </div>
          </button> 
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
          // defaultCenter={{ lat: 52.6376, lng: -1.135171 }}
          center={mapCenter}
          defaultZoom={6}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          options={{ zoomControl: isMobile ? false : true }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          onClick={(evt) => onMapClickHandler(evt)}
        > 
          {userLocation.latitude && userLocation.longitude && (
                <Marker
                key={`Моя  геопозиція`}
                lat={userLocation.latitude}
                lng={userLocation.longitude}
                >
                  <div className="my-geolocation-icon-bg">
                    <div className="my-geolocation-icon"></div>
                  </div>
                </Marker>
            )}
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;
 
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={`crime-${cluster.properties.pointId}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  onClick={() =>
                    onClicMarkerHandler(
                      cluster.properties.pointId,
                      latitude,
                      longitude,
                      cluster
                    )
                  }
                  className="point-marker"
                >
                  <div className="point-marker-icon" style={{background: cluster.properties.iconUrl}}></div>
                  {/* <img src={cluster.properties.iconUrl} alt="icon" /> */}
                  {!isMobile &&
                    cluster.properties.pointId === openedMarkerId && (
                      <MarkerInfo
                        cluster={cluster}
                        isMobile={isMobile}
                        closeMarkerInfo={() => onMapClickHandler()}
                      />
                    )}
                </div>
              </Marker>
            );
          })}
        </GoogleMapReact>
      </div>
      {isMobile && openedMarker !== null && (
        <MarkerInfo 
          cluster={openedMarker} 
          isMobile={isMobile} 
          openAppoitDoctorPage={openAppoitDoctorPage}/>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  lpu: state.lpus.lpu,
  currentLpu: state.lpus.currentLpu,
  userLocation: state.user.userData.userLocation
});

export default connect(mapStateToProps, null)(MapNew);
