import axios from "axios";
import { TokenService } from "../token/storage.service";
let baseURLTaken;

const ApiService = {
  init(baseURL) {
    baseURLTaken = baseURL;
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          if (error.response.data.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
        }
        return Promise.reject(error);
      }
    );
    axios.defaults.baseURL = baseURL;
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken()}`;
  },

  setTempHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getTempToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  postChachedData(resource, data) {
    return fetch(baseURLTaken + resource, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json();
    });
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  getHeaders() {
    return axios.defaults.headers.common["Authorization"];
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
