import { combineReducers } from "redux";
import user from "./user/index";
import { layouts } from "./layouts/layouts";
import { lpus } from "./lpu/lpu";
import { refund } from "./refund/refund";
import { control } from "./control/control";
import { visits } from "./visits/visits";

const allReducers = combineReducers({
  user,
  layouts,
  lpus,
  refund,
  control,
  visits,
});

const reducers = (state, action) => {
  return allReducers(state, action);
};

export default reducers;
